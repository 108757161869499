import axios, { AxiosRequestConfig } from 'axios';
import logger from '../utils/logger';
import { GridPaginationModel } from '@mui/x-data-grid';
import { Pagination } from 'entities/base/Pagination';

type FilterType = {
  type: string;
  values: string[];
};

export type PagedResult<T> = {
  items?: T;
  pagination: Pagination;
};

export interface QueryOptions extends GridPaginationModel {
  orderBy?: string;
  search?: string;
  filters?: string; // FilterType[] // TODO implement
  //filtering?: GridFilterModel;
}

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_BASEURL });

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('bs-token');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    // config.headers['accept'] = '*/*';
    // config.timeout = 5000;
    config.headers['Content-Type'] = 'application/json';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error.response.status === 401 && !window.location.href.includes('/login')) {
    //   window.location.pathname = '/login';
    // }
    // TODO FIX ANY :(
    return Promise.reject(error.response.status);
  }
);

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export default function useAxios() {
  async function get<T>(url: string, options?: QueryOptions): Promise<T> {
    const response = await axiosInstance.get(url, { params: options }).catch((e) => logger.logError(`Failed to GET ${url} with message: ${JSON.stringify(e)}`));
    logger.log(`GET '${url}' response:`, response);
    return response?.data as T;
  }

  async function getText(url: string): Promise<string> {
    const response = await axiosInstance.get(url).catch((e) => logger.logError(`Failed to GETTEXT ${url} with message: ${JSON.stringify(e)}`));
    logger.log(`GET '${url}' response:`, response);
    return response?.data;
  }

  async function getFile(url: string): Promise<Blob> {
    const response = await axiosInstance.get(url, { responseType: 'blob' }).catch((e) => logger.logError(`Failed to GET ${url} with message: ${JSON.stringify(e)}`));
    logger.log(`GETFILE '${url}' response:`, response);
    return response?.data as Blob;
  }

  async function postFile(url: string, files: FileList): Promise<{ ok: boolean; message: string }> {
    let success = true;
    var response = await axiosInstance
      .post(url, files, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch(() => (success = false));

    logger.log('UPLOAD:\r\n', typeof response === 'boolean' ? response : response.data);

    return { ok: success, message: typeof response !== 'boolean' ? response.data : '' };
  }

  async function getPaged<T>(url: string, options?: QueryOptions): Promise<PagedResult<T> | undefined> {
    try {
      const response = await axiosInstance.get<T>(url, { params: options });
      const pagination: Pagination = JSON.parse(response.headers['x-pagination']);
      logger.log(`GETPAGED '${url}' response:`, response);
      return { items: response?.data, pagination };
    } catch (e) {
      logger.logError(`Failed to GETPAGED ${url} with message: ${JSON.stringify(e)}`);
    }

    return undefined;
  }

  async function patch<T>(url: string, data?: T): Promise<boolean> {
    let result = true;
    const response = await axiosInstance.patch(url, data).catch((e) => {
      logger.logError(`Failed to PATCH ${url} with message: ${JSON.stringify(e)}`);
      result = false;
    });

    logger.log(`PATCH '${url}' response:`, response);
    return result;
  }

  async function put<T>(url: string, data?: T): Promise<boolean> {
    const response = await axiosInstance.put(url, data).catch((e) => {
      logger.logError(`Failed to PATCH ${url} with message: ${JSON.stringify(e)}`);
      return false;
    });

    logger.log(`PATCH '${url}' response:`, response);
    return true;
  }

  async function post<T>(url: string, data?: T): Promise<boolean> {
    let result = true;
    const response = await axiosInstance.post(url, data, { headers: { 'Content-Type': 'text/json' } }).catch((e) => {
      logger.logError(`Failed to POST ${url} with message: ${JSON.stringify(e)}`);
      result = false;
    });

    logger.log(`POST '${url}' [success: ${result}] response:`, response);
    return result;
  }

  async function postWithUrl<T>(url: string): Promise<T> {
    const response = await axiosInstance
      .post(url, {}, { headers: { 'Content-Type': 'text/json' } })
      .catch((e) => logger.logError(`Failed to POST ${url} with message: ${JSON.stringify(e)}`));

    logger.log(`POST '${url}' response:`, response);
    return response?.data as T;
  }

  async function postWithFormData<T1, T2>(url: string, data?: T1): Promise<T2> {
    const response = await axiosInstance
      .post(url, data, { headers: { 'Content-Type': 'text/json' } })
      .catch((e) => logger.logError(`Failed to POST ${url} with message: ${JSON.stringify(e)}`));

    logger.log(`POST '${url}' response:`, response);
    return response?.data as T2;
  }

  async function del<T>(url: string, data?: T): Promise<boolean> {
    let result = true;
    const response = await axiosInstance.delete(url, { params: data }).catch((e) => {
      logger.logError(`Failed to DELETE ${url} with message: ${JSON.stringify(e)}`);
      result = false;
    });

    logger.log(`DELETE '${url}' response:`, response);
    return result;
  }

  return { get, getText, getFile, postFile, getPaged, put, post, postWithUrl, postWithFormData, patch, del };
}
