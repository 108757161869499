import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import {
  DataGrid as MuiDataGrid,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridSortDirection,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { Box, CircularProgress, Divider, List, ListItem, TablePagination, Typography } from '@mui/material';
import { Entity } from '../../entities/base/Entity';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import useAxios, { PagedResult, QueryOptions } from 'hooks/useAxios';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import React from 'react';
import { SearchField } from 'components/fields/SearchField';
import useAppContext from 'hooks/useAppContext';

export interface DataGridActionProps<T extends GridValidRowModel> {
  actions?: GridColDef<T>[];
}

export interface RenderListItemProps {
  entity: Entity;
  disabled?: boolean;
}

//TODO spilt types for server and rows rendering
type DataGridProps = {
  id?: string;
  rows?: Entity[];
  dataUrl?: string;
  orderBy?: string;
  reload?: Symbol; // reload data with default pagination model
  refresh?: Symbol; // refresh data with current pagination model
  isLoading?: boolean;
  columnDefinitions: GridColDef[];
  hideHeader?: boolean;
  hideFooter?: boolean;
  noDataMessage?: string;
  pageSizeOptions?: number[];
  autoHeight?: boolean;
  disableRowSelect?: boolean;
  api?: React.MutableRefObject<GridApiCommunity>;
  customToolbar?: () => JSX.Element;
  onRowClick?: (entityId: number) => void;
  listItemTemplate?: (entity: Entity) => ReactNode;
};

const InitialModelState: GridInitialStateCommunity = {
  // filter: {
  //   filterModel: {
  //     items: rows,
  //     quickFilterValues: ['quick', 'filter'],
  //   },
  // },
  pagination: {
    paginationModel: {
      page: 0,
      pageSize: 10,
    },
  },
  sorting: {
    sortModel: [
      {
        field: 'name',
        sort: 'desc',
      },
    ],
  },
};

function HeaderToolbar() {
  return (
    <GridToolbarContainer sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
      {/* <GridToolbarExport printOptions={{ disableToolbarButton: true }} /> */}
      <GridToolbarQuickFilter debounceMs={1000} />
    </GridToolbarContainer>
  );
}

type RenderListItemsProps = {
  items?: Entity[];
  noDataMessage?: string;
  template: (entity: Entity) => ReactNode;
};

function RenderListItems({ items, noDataMessage, template }: RenderListItemsProps) {
  return (
    <List sx={{ mt: 1, width: '100%', bgcolor: 'background.paper' }}>
      {(items?.length ?? 0) === 0 && (
        <ListItem>
          <Typography>{noDataMessage ?? `No data`} </Typography>
        </ListItem>
      )}
      {items?.map((item, index, array) => (
        <div key={item.id}>
          {template(item)}
          {index !== array.length && <Divider component='li' />}
        </div>
      ))}
    </List>
  );
}

export const DataGrid = ({
  api,
  id,
  autoHeight,
  dataUrl,
  reload,
  refresh,
  isLoading,
  orderBy,
  rows,
  noDataMessage,
  disableRowSelect,
  columnDefinitions,
  pageSizeOptions,
  hideFooter,
  hideHeader,
  onRowClick,
  customToolbar,
  listItemTemplate,
}: DataGridProps) => {
  const { getPaged } = useAxios();

  const { context } = useAppContext();

  const [_rows, setRows] = useState<Entity[]>([]);
  const [_dataUrl, setDataUrl] = useState(dataUrl);

  const [_reload, setReload] = useState(reload);
  const [_refresh, setRefresh] = useState(refresh);
  const [loading, setLoading] = useState(false); //isLoading ?? true);

  const [listView, setListView] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: orderBy?.split(' ')[0] ?? 'name',
      sort: (orderBy?.split(' ')[1] as GridSortDirection) ?? 'asc',
    },
  ]);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: listView ? 5 : 10,
  });

  const [model, setModel] = useState<QueryOptions>({
    page: 0,
    pageSize: listView ? 5 : 10,
    orderBy: orderBy ?? 'name asc',
    search: '',
  });

  const [rowCount, setRowCount] = useState(0);
  //const [pagination, setPagination] = useState<GridPaginationModel>({ page: 0, pageSize: 10 })
  const [rowCountState, setRowCountState] = useState(rowCount);

  const handlePagination = (paginationModel: GridPaginationModel) => {
    setLoading(true);
    setPaginationModel(paginationModel);
    setModel({ ...model, ...paginationModel });
  };

  const handleSorting = (sortModel: GridSortModel) => {
    setLoading(true);
    setSortModel(sortModel);
    const orderBy = sortModel.map((m) => `${m.field} ${m.sort}`).join(', ');
    setModel({ ...model, orderBy: orderBy });
  };

  const handleSearch = (value: string) => {
    setLoading(true);
    setModel({ ...model, ...{ ...InitialModelState.pagination, page: 0, pageSize: listView ? 5 : 10 }, search: value });
  };

  const handleFilters = useCallback((filterModel: GridFilterModel) => {
    setLoading(true);
    const search = filterModel.quickFilterValues?.at(0);
    setModel({ ...model, ...{ ...InitialModelState.pagination, pageSize: listView ? 5 : 10 }, search: search });
  }, []);

  // const filterDuplicates = (e: Entity) => {
  //   var result = e as InspectionResult;
  //   if (result && result.results?.length !== 0 && result.results?.length !== 2 && result.results?.length !== 7) {
  //     return result;
  //   }

  //   return null;
  // };

  const renderData = (result?: PagedResult<Entity[]>) => {
    if (result) {
      //const pagination: Pagination = JSON.parse(response.headers['x-pagination']);
      setPaginationModel({ page: result.pagination.currentPage, pageSize: result.pagination.pageSize });
      //logger.log('renderData:', response);
      setRowCount(result.pagination.totalCount);
      setRows(result.items ?? []); ///response.data as EntityID[]);
      // logger.logError(
      //   'DUPLICATE DATA',
      //   result.items?.filter(filterDuplicates).map((i) => i.id)
      // );
    } else setRows([]);
    setLoading(false);
  };

  const fetchData = (options?: QueryOptions) => {
    //setLoading(true);
    if (dataUrl) {
      // setRows([]);
      getPaged<Entity[]>(dataUrl, options).then(renderData);
    } else {
      setRows([]);
      setLoading(false);
    }
  };

  const NoData = () => (
    <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
      {noDataMessage ?? 'No data found'}
    </Box>
  );

  useEffect(() => {
    setRowCountState((prevRowCountState) => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    fetchData(model);
  }, [model]); //, invalidateData]);

  useEffect(() => {
    // load data from rows
    if (rows) {
      setRowCount(rows.length);
      setRows(rows);
      setLoading(false);
    }
  }, [rows]);

  useEffect(() => {
    if (dataUrl) {
      setLoading(true);
      fetchData({
        page: 0,
        pageSize: listView ? 5 : 10,
        orderBy: orderBy ?? 'name asc',
        search: '',
      });
    } else setLoading(false);
  }, [_reload, _dataUrl]);

  useEffect(() => {
    if (dataUrl) fetchData(model);
    else setLoading(false);
  }, [_refresh]);

  useEffect(() => {
    if (dataUrl && dataUrl !== _dataUrl) setDataUrl(dataUrl);
  }, [dataUrl]);

  useEffect(() => {
    if (reload !== _reload) setReload(Symbol());
  }, [reload]);

  useEffect(() => {
    if (refresh !== _refresh) setRefresh(Symbol());
  }, [refresh]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setListView(entries[0].contentRect.width < 600);
    });
    if (containerRef.current) observer.observe(containerRef.current);
    return () => {
      containerRef.current && observer.unobserve(containerRef.current);
    };
  }, []);

  return (
    <div ref={containerRef}>
      {listView && listItemTemplate ? (
        <Box>
          {!hideHeader && <SearchField onChange={handleSearch} />}
          {!loading && <RenderListItems items={_rows} template={listItemTemplate} noDataMessage={noDataMessage} />}
          {loading && (
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress color='inherit' />
            </Box>
          )}
          {/* {!loading && 
            {_rows.length === 0 ? (
              <ListItem>
                <Typography>{`No data`} </Typography>
              </ListItem>
            ) : (
              _rows.map(renderListItem)
            )}*/}
          {rowCount > 5 && (
            <TablePagination
              component='div'
              count={rowCount}
              page={model.page}
              labelRowsPerPage={''}
              showFirstButton={true}
              onPageChange={(_, page) => handlePagination({ ...paginationModel, page: page })}
              rowsPerPage={5}
              rowsPerPageOptions={[]}
              // onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString()}-${to.toLocaleString()} of ${count.toLocaleString()}`}
            />
          )}
        </Box>
      ) : (
        <Box
          ref={containerRef}
          key={`container_datagrid_${id ? '_' + id : ''}`.trim()}
          sx={{
            height: '100%',
            width: '100%',
            '& .MuiDataGrid-root': {
              '& .MuiDataGrid-toolbarContainer': {
                pl: 3,
                pr: 2,
                pt: 2,
                '& .MuiButton-root': {
                  p: 1,
                  color: 'common.white',
                  borderRadius: 1.5,
                  bgcolor: 'primary.main',
                },
                '& .MuiFormControl-root > .MuiInput-root': {
                  p: 0.6,
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 2,
                  bgcolor: 'grey.50',
                },
                '& .MuiFormControl-root > .MuiInputBase-root:after': {
                  display: 'none',
                },
                '& .MuiFormControl-root > .MuiInputBase-root:before': {
                  display: 'none',
                },
                '& .MuiFormControl-root > .Mui-focused': {
                  border: '1px solid',
                  borderColor: 'primary.main',
                },
              },
            },
          }}
        >
          <MuiDataGrid
            // key={`datagrid_${key}`}
            rows={_rows}
            apiRef={api}
            loading={loading}
            rowHeight={52}
            autoHeight={autoHeight ?? true}
            rowCount={_dataUrl ? rowCountState : undefined}
            disableRowSelectionOnClick={true} //disableRowSelect ?? true}
            columns={columnDefinitions}
            initialState={InitialModelState}
            filterMode={_dataUrl ? 'server' : 'client'}
            pageSizeOptions={pageSizeOptions ?? [10, 50, 100]}
            paginationMode={_dataUrl ? 'server' : 'client'}
            sortModel={sortModel}
            paginationModel={paginationModel}
            hideFooterPagination={rowCountState < paginationModel.pageSize}
            onPaginationModelChange={handlePagination}
            onSortModelChange={handleSorting}
            onFilterModelChange={handleFilters}
            onRowClick={(e) => (onRowClick ? onRowClick(e.row.id) : null)}
            slots={{
              noRowsOverlay: NoData,
              toolbar: customToolbar ?? ((hideHeader ?? true) ? undefined : HeaderToolbar),
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: hideHeader ?? true,
                printOptions: { disableToolbarButton: true },
              },
              pagination: {
                showFirstButton: true,
                showLastButton: true,
                labelDisplayedRows: ({ from, to, count }) => `${from.toLocaleString()}-${to.toLocaleString()} of ${count.toLocaleString()}`,
              },
            }}
            hideFooter={hideFooter ?? false}
            sx={{ width: '100%' /*, marginBottom: '50px'*/ }}
            //TODO alternate rows not working
            //getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even' : 'mui-odd')}
          />
        </Box>
      )}
    </div>
  );
};
