import { GridColDef } from '@mui/x-data-grid';
import { DataGrid, DataGridActionProps } from 'components/dataGrid/DataGrid';
import { InspectionType } from 'entities';
import { Frequency } from 'entities/enums';
import useAppContext from 'hooks/useAppContext';
import { ReactNode } from 'react';

interface InspectionTypesTableActions extends DataGridActionProps<InspectionType> {}

export default function InspectionTypesTable({ actions }: InspectionTypesTableActions) {
  const { context } = useAppContext();

  const columns: GridColDef<InspectionType>[] = [
    { field: 'name', headerName: 'Name', width: 400, filterable: false },
    // {
    //   field: 'systemType',
    //   headerName: 'System Type',
    //   width: 400,
    //   filterable: false,
    //   renderCell(params) {
    //     return params.value?.name ?? '';
    //   },
    // },
    {
      field: 'frequency',
      headerName: 'Frequency',
      width: 250,
      filterable: false,
      renderCell(params): ReactNode {
        return (
          <>
            {params.row.frequency !== undefined
              ? `${Frequency[params.row.frequency]}`.replace('Odd', ' (Odd)').replace('Even', ' (Even)').replace('Quadrennial', 'Quadrennial (every 4 years').trim()
              : 'N/A'}
          </>
        );
      },
    },
  ];

  return <DataGrid id={'inspectionTypesTable'} dataUrl={`/api/inspectionType/query/${context?.clientId}`} columnDefinitions={actions ? [...actions, ...columns] : columns} />;
}
