import { DeviceVM, SignalVM } from 'entities/viewModels';
import { List, ListItem, ListItemText, ListItemIcon, Button, Box, Typography } from '@mui/material';
import { Sensors } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { getDateString } from 'utils/dateHelper';
import useAppContext from 'hooks/useAppContext';
import { SystemState } from 'entities/enums';

type DeviceListProps = {
  items?: DeviceVM[];
  noDataText?: string;
};

export const DeviceList = ({ items, noDataText }: DeviceListProps) => {
  const { context } = useAppContext();
  const [devices, setDevices] = useState<DeviceVM[]>();

  const [showAll, setShowAll] = useState(false);

  useEffect(() => setDevices(items), [items]);

  return (
    <Box>
      <List dense={true} sx={{ maxHeight: 450, overflow: 'auto' }}>
        {(devices?.length ?? 0) > 0 ? (
          devices?.map(
            (device) =>
              device && (
                <ListItem key={device.id}>
                  <ListItemText primary={device.name} secondary={<Box></Box>} />
                </ListItem>
              )
          )
        ) : (
          <ListItem key={0}>
            <ListItemText primary={noDataText ?? 'No devices found'} />
          </ListItem>
        )}
      </List>
      {/* {(signals?.length ?? 0) > 5 && <Button>Show all</Button>} */}
    </Box>
  );
};
