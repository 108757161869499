import { useState, useMemo } from 'react';
import { useMap } from 'react-leaflet';
import { useEventHandlers } from '@react-leaflet/core';
import { AddDevice } from './contextMenu/menuItems/AddDevice';
import { AddLabel } from './contextMenu/menuItems/AddLabel';
import ContextMenu, { MouseEventProps } from './contextMenu/ContextMenu';
import useAppContext from 'hooks/useAppContext';
import { AppRoles } from 'entities/enums';
import { inRole } from 'utils/extensions';
import useMaps from 'hooks/useMaps';

export default function MapMenu() {
  const lmap = useMap();
  const { maps } = useMaps();
  const { contact, context } = useAppContext();
  const [mouseEvent, setMouseEvent] = useState<MouseEventProps>();

  //@ts-ignore
  const handlers = useMemo(() => ({ contextmenu: (e: React.MouseEvent) => setMouseEvent({ event: e.originalEvent, position: e.latlng }) }), []);
  // @ts-ignore TODO fix if needed
  useEventHandlers({ instance: lmap }, handlers);

  return (
    inRole(contact?.role, [AppRoles.Admin, AppRoles.Inspector]) && (
      <ContextMenu mouseEvent={mouseEvent}>
        <AddDevice position={mouseEvent?.position} onClick={() => setMouseEvent(undefined)} disabled={maps?.length === 0} />
        <AddLabel position={mouseEvent?.position} onClick={() => setMouseEvent(undefined)} disabled={maps?.length === 0} />
      </ContextMenu>
    )
  );
}
