import { SignalVM } from 'entities/viewModels';
import { List, ListItem, ListItemText, ListItemIcon, Button, Box, Typography } from '@mui/material';
import { Sensors } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { getDateString } from 'utils/dateHelper';
import useAppContext from 'hooks/useAppContext';
import { SystemState } from 'entities/enums';

type SignalListProps = {
  items?: SignalVM[];
  noDataText?: string;
};

export const SignalList = ({ items, noDataText }: SignalListProps) => {
  const { context } = useAppContext();
  const [signals, setSignals] = useState<SignalVM[]>();

  const [showAll, setShowAll] = useState(false);

  useEffect(() => setSignals(items), [items]);

  return (
    <Box>
      <List dense={true} sx={{ maxHeight: 450, overflow: 'auto' }}>
        {(signals?.length ?? 0) > 0 ? (
          signals
            ?.sort((a, b) => new Date(b.date!!).getTime() - new Date(a.date!!).getTime())
            .map((s) => (
              <ListItem key={s.id}>
                <ListItemIcon>
                  <Sensors />
                </ListItemIcon>
                <ListItemText
                  primary={s.type?.name ?? `UNTYPED ${s.text}`}
                  secondary={
                    <Box>
                      <Typography>{getDateString(s.date, context?.timeZone) ?? ' NO DATE'}</Typography>
                      <Typography>System state: {SystemState[s.systemState]}</Typography>
                    </Box>
                  }
                />
              </ListItem>
            ))
        ) : (
          <ListItem key={0}>
            <ListItemText primary={noDataText ?? 'No signals raised'} />
          </ListItem>
        )}
      </List>
      {/* {(signals?.length ?? 0) > 5 && <Button>Show all</Button>} */}
    </Box>
  );
};
