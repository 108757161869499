import { ReactNode, useEffect, useState } from 'react';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender, useGridApiRef } from '@mui/x-data-grid';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Box, ListItem, ListItemText, Typography } from '@mui/material';
import { DataGrid } from 'components/dataGrid/DataGrid';
import useAppContext from 'hooks/useAppContext';
import useSignals from 'hooks/useSignals';
import { SignalVM } from 'entities/viewModels';
import { MarkerType } from 'components/maps/base/MapMarker';
import { AppRoles } from 'entities/enums';
import { inRole } from 'utils/extensions';
import { Entity } from 'entities/base/Entity';
import { IconButton, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LocationIcon from '@mui/icons-material/LocationOn';
import { Add } from '@mui/icons-material';
import useMaps from 'hooks/useMaps';
import useNotification from 'hooks/useNotification';
import { MapItem } from 'entities';
import { getDateString } from 'utils/dateHelper';

type SignalsTableProps = {
  type: MarkerType;
  refresh?: Symbol;
  showActions?: boolean;
  pageSizeOptions?: number[];
  customColumns?: GridColDef<SignalVM>[];
};

// const RenderListItemTemplate = (entity: Entity) => {
//   const item = entity as SignalVM;

//   return (
//     <ListItem>
//       <Box display={'flex'} flexDirection={'column'}>
//         <ListItemText primary={item.message} />
//         <ListItemText primary={item.type?.name} secondary={item.device?.address} />
//       </Box>
//     </ListItem>
//   );
// };

export default function SignalsTable({ type, refresh, pageSizeOptions, showActions, customColumns }: SignalsTableProps) {
  const apiRef = useGridApiRef();
  const navigate = useNavigate();
  const { warning } = useNotification();
  const { getMapItem, getMapItemByDeviceId, moveToItem } = useMaps();

  const { context, contact } = useAppContext();
  const { signal, signalState } = useSignals();

  const isAuthorized = inRole(contact?.role, [AppRoles.Admin, AppRoles.Inspector]);

  const [dataUrl, setDataUrl] = useState<string>();
  const [_refresh, setRefresh] = useState<Symbol>();

  const [mapItem, setMapItem] = useState<MapItem>();
  const handleShowOnMapClick = async (item: SignalVM) => {
    const address = item?.address;
    const parentDeviceID = item?.device?.parentDeviceId;
    // console.log('address', address);
    if (address) {
      const mapItemDevice = await getMapItem(item.systemID, address);
      if ((mapItemDevice?.id ?? 0) > 0) {
        setMapItem(mapItemDevice);
        return;
      }
    }

    if (parentDeviceID) {
      const mapItemParentDevice = await getMapItemByDeviceId(parentDeviceID);
      if ((mapItemParentDevice?.id ?? 0) > 0) {
        setMapItem(mapItemParentDevice);
        return;
      }
    }

    warning(`Device ${address ? `'${address}' ` : ''}not found on map`);
  };

  const SignalDeviceTableCellAction = (item: SignalVM): ReactNode => {
    if (!item?.device?.address && !item?.address) return <></>;

    // show add button for undefined device and signal contains name & address
    if (item?.device?.address === undefined && item?.address)
      return inRole(contact?.role, [AppRoles.Admin, AppRoles.Inspector]) ? (
        <Stack direction='row' spacing={1} height={'100%'} alignItems='center'>
          <IconButton
            size='small'
            title='Add device'
            color='inherit'
            onClick={() => {
              navigate(`/devices/0`, { state: { name: item?.message ?? '', address: item.address } });
            }}
          >
            <Add />
          </IconButton>
        </Stack>
      ) : (
        <></>
      );

    return (
      <Stack direction='row' spacing={1} height={'100%'} alignItems='center'>
        <IconButton size='small' title='Show on map' color='inherit' onClick={() => handleShowOnMapClick(item)}>
          <LocationIcon />
        </IconButton>
      </Stack>
    );
  };

  const RenderListItemTemplate = (entity: Entity) => {
    const item = entity as SignalVM;

    return (
      <ListItem sx={{ pl: 1 }}>
        <Box display={'flex'} flexDirection={'column'}>
          <Typography>{item.message}</Typography>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            {SignalDeviceTableCellAction(item)}
            <Box display={'flex'} flexDirection={'column'}>
              <Typography variant='caption'>{'Adddress: ' + item.address}</Typography>
              <Typography variant='caption'>{'Date: ' + getDateString(item.date, context?.timeZone)}</Typography>
              <Typography variant='caption'>{'Type: ' + item.type?.name}</Typography>
            </Box>
          </Box>
        </Box>
      </ListItem>
    );
  };

  const renderTypeCell = (params: GridRenderCellParams<SignalVM, any, any, GridTreeNodeWithRender>): ReactNode => {
    return (
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          marginRight: '10px',
        }}
      >
        {params.row.type?.name}
      </Box>
    );
  };

  const actionColumns: GridColDef<SignalVM>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return SignalDeviceTableCellAction(params.row);
      },
      // renderCell: SignalDeviceTableCellAction,
    },
  ];

  const columns: GridColDef<SignalVM>[] = [
    ...(showActions ? actionColumns : []),
    ...(customColumns ?? []),
    {
      field: 'signalType',
      headerName: 'Type',
      filterable: true,
      width: 175,
      renderCell: renderTypeCell,
    },
    {
      field: 'message',
      headerName: 'Signal',
      width: 300,
      filterable: false,
      renderCell(params): ReactNode {
        var message = params.value as string;
        return <div title={message}>{message}</div>;
      },
    },
    {
      field: 'device.Address',
      headerName: 'Address',
      width: 150,
      filterable: false,
      renderCell(params) {
        return params.row.device && isAuthorized ? (
          <Link component={RouterLink} to={`/devices/${params.row.deviceID}`} sx={{ textDecoration: 'underline' }}>
            {params.row.device?.address ?? ''}
          </Link>
        ) : (
          <>{params.row.device?.address ?? ''}</>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      filterable: false,
      width: 200,
      renderCell(params) {
        return <>{getDateString(params.value, context?.timeZone)}</>;
      },
    },
  ];

  // useEffect(() => {
  //   setShowNotification(false);
  // }, [_refreshData]);

  useEffect(() => {
    if (mapItem) {
      navigate('/maps');
      moveToItem(mapItem);
    }
  }, [mapItem]);

  useEffect(() => {
    if (signal && type === 'All') setRefresh(Symbol());
    if (signalState && type === 'Active') setRefresh(Symbol());
  }, [signalState, signal]);

  useEffect(() => {
    setRefresh(Symbol());
  }, [refresh]);

  useEffect(() => {
    setDataUrl(type === 'All' ? `/api/signal/query/${context?.systemId}/all` : `/api/signal/query/${context?.systemId}/active`);
  }, [type, context?.systemId]);

  useEffect(() => {
    setDataUrl(type === 'All' ? `/api/signal/query/${context?.systemId}/all` : `/api/signal/query/${context?.systemId}/active`);
  }, []);

  return (
    <DataGrid
      api={apiRef}
      refresh={_refresh}
      hideHeader={false}
      key={'signalsTable'}
      pageSizeOptions={pageSizeOptions}
      // rows={states.length > 0 ? states.map((s) => s.signalActivated) : []}
      dataUrl={dataUrl}
      orderBy={'date desc'}
      columnDefinitions={columns}
      listItemTemplate={RenderListItemTemplate}
    />
  );
}
