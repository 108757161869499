import { GridColDef } from '@mui/x-data-grid';
import { DataGrid, DataGridActionProps } from 'components/dataGrid/DataGrid';
import { SystemVM } from 'entities/viewModels';
import useAppContext from 'hooks/useAppContext';

interface SystemsTableActions extends DataGridActionProps<SystemVM> {}

export default function SystemsTable({ actions }: SystemsTableActions) {
  const { context } = useAppContext();
  const columns: GridColDef<SystemVM>[] = [
    {
      field: 'id',
      headerName: 'ID',
      filterable: false,
      width: 100,
    },
    {
      field: 'site',
      headerName: 'Site',
      filterable: false,
      width: 100,
      renderCell(params) {
        return params.row.building?.site?.code;
      },
    },
    {
      field: 'building',
      headerName: 'Building',
      filterable: false,
      width: 200,
      renderCell(params) {
        return params.row.building?.name;
      },
    },
    {
      field: 'name',
      headerName: 'System',
      filterable: false,
      width: 300,
    },
    {
      field: 'maps',
      headerName: 'Maps',
      filterable: false,
      width: 400,
      renderCell(params) {
        const title = params.row.maps?.map((m) => m.name.replace('Concourse', 'Con'))?.join(', ') ?? '';
        return <div title={title}>{title}</div>;
      },
    },
  ];

  return (
    <DataGrid
      id={'systemsTable'}
      dataUrl={`/api/system/query/${context?.clientId}`}
      hideHeader={false}
      orderBy={'id asc'}
      columnDefinitions={actions ? [...actions, ...columns] : columns}
    />
  );
}
