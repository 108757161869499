import { LayerGroup, SVGOverlay, WMSTileLayer, useMap } from 'react-leaflet';
import { useEffect, useMemo, useRef, useState } from 'react';
import useAppContext from 'hooks/useAppContext';
import logger from 'utils/logger';
import { LatLngBounds, SVGOverlay as LeafletSVGOverlay, TileLayer } from 'leaflet';
import useAxios from 'hooks/useAxios';
import { MAPCENTER, MapType, MAXZOOM, MINZOOM } from './MapContainer';
import { useWindowSize } from 'hooks/useWindowSize';
import useMaps from 'hooks/useMaps';
import useConfirm from 'hooks/useConfirm';
import { MapMarkerLayer } from './base/MapMarkerLayer';
import { AppRoles } from 'entities/enums';
import { inRole } from 'utils/extensions';
import { renderLayerItems, showLayer } from 'helpers/LayerHelper';
import { MapOverlay } from 'entities';
import useNotification from 'hooks/useNotification';
import { MapOverlayComponent } from './MapOverlay';
import { MapItemVM } from 'entities/viewModels';

type MapLayersProps = {
  mapType: MapType;
};

export function MapLayers({ mapType }: MapLayersProps) {
  const lmap = useMap();
  const { get } = useAxios();
  const { width } = useWindowSize();
  const { contact, context } = useAppContext();
  const { map, layerItems, layers, mapItem, addMarker, moveToItem, refreshMap, setLayerItems, setMapOverlay } = useMaps();

  const mapOverlay = map?.overlays?.find((l) => l.name.startsWith('layers'));

  const { confirm, ConfirmDialog } = useConfirm();
  const { info, error, success } = useNotification();

  const tileOverlayRef = useRef<TileLayer.WMS>(null);

  const [zoom, setZoom] = useState(MINZOOM);

  const [mapId, setMapId] = useState<number>();

  const overlayHandlers = useMemo(() => ({}), []);
  const [overlay, setOverlay] = useState<string>();
  const overlayRef = useRef<LeafletSVGOverlay>(null);
  const [overlayBounds, setOverlayBounds] = useState<LatLngBounds>();

  const [disclaimerBounds, setDisclaimerBounds] = useState<LatLngBounds>();
  const getBounds = (overlay?: MapOverlay): LatLngBounds | undefined => (overlay ? new LatLngBounds([overlay.south, overlay.west], [overlay.north, overlay.east]) : undefined);

  const resetMapView = () => {
    setZoom(MINZOOM);
    lmap.setView(MAPCENTER, MINZOOM, { animate: false });
  };

  const zoomToItem = () => {
    if (mapItem) {
      lmap.flyTo([mapItem.lat, mapItem.lng], 7);
      lmap.invalidateSize(true);
      if (!mapItem.deviceId) moveToItem(undefined); // reset only when its not a device, reset done after popup!!!
    }
  };

  useEffect(() => {
    if (overlayRef.current !== null && mapOverlay && mapType === 'edit' && layers.generate) {
      resetMapView();
      renderLayerItems(overlayRef, lmap, mapOverlay).then((items) => {
        const _items = items as unknown as MapItemVM[];
        info(`Generated ${_items.length} items from layers`);
        setLayerItems(_items);
      });
    } else setLayerItems([]);
  }, [layers]);

  useEffect(() => {
    //if (overlayRef.current) {
    setMapOverlay(mapOverlay);
    //}
  }, []); // [overlayRef.current]);

  // useEffect(() => {
  //   if (zonesOverlayRef.current) {
  //     // TODO set visibility based on MapZone.UID
  //     //console.log(zoneOverlayRef.current?.getElement());
  //   }
  // }, [zonesOverlayRef, zonesOverlay]);

  useEffect(() => {
    zoomToItem();
  }, [mapItem]);

  useEffect(() => {
    if (overlayRef && layers) showLayer(overlayRef, layers, mapType);
  }, [Object.values(layers)]);

  useEffect(() => {
    if (map?.id !== mapId && !mapItem) {
      resetMapView();
      setOverlay(undefined);
    }
  }, [map?.id]);

  useEffect(() => {
    if (map) setOverlayBounds(getBounds(mapOverlay!!));
  }, [map]);

  useEffect(() => resetMapView(), [context?.systemId]);

  useEffect(() => {
    if (!map) {
      setOverlay(undefined);
      return;
    }

    logger.log('INIT MapLayer.map', map);

    if (!disclaimerBounds) setDisclaimerBounds(getBounds(mapOverlay!!));

    if (tileOverlayRef.current && map) {
      console.log('MAP', map);
      get<string>(`/api/map/layers/${context?.systemId}/${map?.id}/${map.overlays?.find((o) => o.name.startsWith('layers'))?.name ?? 'layers'}`).then((svg) => {
        if (svg && svg.length > 0) setOverlay(svg);
      });

      setMapId(map.id);
      // setLayer(map.layer);
      tileOverlayRef.current.setUrl(map.url);
      lmap.invalidateSize(true);
      lmap.zoomControl.addTo(lmap);
      //setMapInfo({ ...mapInfo, mapBounds: lmap.getBounds() });
    } else {
      setMapId(undefined);
      tileOverlayRef?.current?.setUrl('');
    }

    // only have this mouse event for adding markers
    if (map && mapType === 'edit' && inRole(contact?.role, [AppRoles.Admin, AppRoles.Inspector])) {
      lmap.addEventListener('mouseup', (e) => {
        addMarker({
          lat: e.latlng.lat,
          lng: e.latlng.lng,
          mapID: map?.id,
          name: '',
          id: 0,
        });

        refreshMap();
      });
    }

    lmap.addEventListener('zoomend', () => setZoom(lmap.getZoom()));

    zoomToItem();

    return () => {
      addMarker(undefined);
      tileOverlayRef.current?.setUrl('');
      lmap.removeEventListener('mouseup');
      lmap.removeEventListener('zoomend');
    };
  }, [map]);

  return (
    <>
      <ConfirmDialog />
      {/* {discalimerBounds && <ImageOverlay interactive={false} url='disclaimer.png' bounds={discalimerBounds} opacity={0.2} />} */}
      <WMSTileLayer
        tms={true}
        format='image/png'
        ref={tileOverlayRef}
        // crossOrigin={true}
        url={map?.url ?? ''}
        minZoom={MINZOOM - (mapType === 'edit' ? 2 : 0)}
        maxZoom={MAXZOOM}
        // maxNativeZoom={MAXZOOM}
        accessToken={localStorage.getItem('bs-token') ?? undefined}
        //errorTileUrl='/empty.png'
      />
      <LayerGroup>
        {map && mapType !== 'edit' && <MapMarkerLayer map={map} mapType={mapType} zoom={zoom} />}

        {map && mapType === 'edit' && <MapMarkerLayer map={map} mapType={mapType} zoom={zoom} layerItems={layerItems} />}

        {overlay && overlayBounds && (
          <SVGOverlay ref={overlayRef} className={mapType === 'edit' ? 'svg-border-red' : ''} key={`overlays_${map?.id}`} bounds={overlayBounds} eventHandlers={overlayHandlers}>
            <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' id={`${`overlays_${map?.id}`}`} dangerouslySetInnerHTML={{ __html: overlay }} />
          </SVGOverlay>
        )}

        {map?.overlays?.filter((o) => o.name.indexOf('layers') === -1).map((o) => <MapOverlayComponent key={o.id} mapType={mapType} overlay={o} />)}
      </LayerGroup>
    </>
  );
}
