import { FormControl, FormLabel, Button, TextField, Box, ButtonGroup, SxProps, Theme, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { InspectionQuestion, InspectionQuestionResult, InspectionResult, Photo } from 'entities';
import { InspectionOutcome } from 'entities/enums/InspectionOutcome';
import useInspection from 'hooks/useInspection';
import { InspectionResultVM, SignalVM } from 'entities/viewModels';
import { addOrUpdateArray } from 'utils/array';
import { LoadingButton } from '@mui/lab';
import { InspectionResultState } from 'entities/enums';
import { CollapsableContainer } from 'components/shared/CollapsableContainer';
import useAppContext from 'hooks/useAppContext';
import { SignalList } from 'collections/SignalList';
import { getDateString } from 'utils/dateHelper';
import useNotification from 'hooks/useNotification';
import useAxios from 'hooks/useAxios';
import { UploadButton } from 'components/shared/UploadButton';
import ImageCarousel from 'components/shared/ImageCarousel';
import { CameraAlt } from '@mui/icons-material';

type InspectionResultFormProps = {
  result?: InspectionResultVM;
  lastRemote?: string;
  disabled?: boolean;
};

export const InspectionResultForm = ({ result, lastRemote, disabled }: InspectionResultFormProps) => {
  const { context } = useAppContext();
  const { get, postFile } = useAxios();
  const { notify, error } = useNotification();
  const [item, setItem] = useState(result!!);

  const questions = result?.inspection?.type?.questions ?? [];

  const [dirty, setDirty] = useState(false);
  const [_error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setInspectionDeviceId, handleInspectionResultSubmit } = useInspection();

  const [helperText, setHelperText] = useState('Please submit a comment');

  const [expanded, setExpanded] = useState(true); //(item?.state ?? InspectionResultState.Pending) === InspectionResultState.Pending);

  const [photos, setPhotos] = useState<Photo[]>();
  const [signals, setSignals] = useState<SignalVM[]>();

  const [importLoading, setImportLoading] = useState(false);

  const getPhotos = () => get<Photo[]>(`/api/photo/inspection/${context?.systemId}/${result?.id}`).then(setPhotos);
  const getSignals = () => get<SignalVM[]>(`/api/signal/inspection/${context?.systemId}/${result?.deviceID}`).then(setSignals);

  const handleImport = async (files: FileList | null) => {
    if (files && files.length > 0) {
      setImportLoading(true);
      var result = await postFile(`/api/photo/upload/inspection/${context?.systemId}/${item.id}`, files);
      if (result.ok) {
        getPhotos();
        var strings = result.message.split('\r\n');
        notify(
          <Box display={'flex'} flexDirection={'column'}>
            {'Upload successfull'}
          </Box>,
          { variant: 'success' }
        );
      } else error('Failed to upload');
      setImportLoading(false);
    }
  };

  const handleExpandClick = () => setExpanded(!expanded);

  const getResult = (id: number) => item?.results?.find((r) => r.inspectionQuestionID === id);

  const getFillColor = () =>
    item.state === InspectionResultState.Pending
      ? '#d9534'
      : item.state === InspectionResultState.Partially
        ? '#ffc0cb'
        : item.state === InspectionResultState.Failed
          ? '#ff0000'
          : '#6cc067';

  const setAnswer = (questionId: number, outcome: InspectionOutcome, comment: string = '') => {
    if (disabled) return;
    const result: InspectionQuestionResult = getResult(questionId) ?? { id: 0, inspectionQuestionID: questionId };

    const update: InspectionResult = {
      ...item!!,
      results: addOrUpdateArray(item?.results ?? [], { ...result, outcome: outcome, comment: comment }, 'inspectionQuestionID'),
    };
    setItem(update);
    setDirty(true);
  };

  const RenderQuestion = (question: InspectionQuestion, result?: InspectionQuestionResult) => {
    const handlOutcomeChange = (questionId: number, outcome: InspectionOutcome) => setAnswer(questionId, outcome);
    const handleCommentChange = (questionId: number, comment: string) => setAnswer(questionId, InspectionOutcome.FAIL, comment);

    const sx: SxProps<Theme> = disabled ? { ':hover': { cursor: 'default', backgroundColor: 'transparent' } } : {};

    return (
      <Box key={question.id} sx={{ mb: 1 }}>
        <Box display='flex' flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1} key={question.id}>
          <FormLabel id={'outcome-label'}>{question?.name}</FormLabel>
          <ButtonGroup size='small' aria-label='Small button group' sx={{ height: 20 }}>
            <Button
              sx={sx}
              key='yes'
              color={result?.outcome === InspectionOutcome.PASS ? 'success' : undefined}
              onClick={() => handlOutcomeChange(question.id, InspectionOutcome.PASS)}
            >
              Yes
            </Button>
            <Button
              sx={sx}
              key='no'
              color={result?.outcome === InspectionOutcome.FAIL ? 'error' : undefined}
              onClick={() => handlOutcomeChange(question.id, InspectionOutcome.FAIL)}
            >
              No
            </Button>
          </ButtonGroup>
        </Box>
        {result?.outcome === InspectionOutcome.FAIL && (
          <TextField
            multiline
            rows={3}
            fullWidth
            error={_error}
            autoComplete='off'
            label='Comment'
            variant='outlined'
            margin={'normal'}
            value={result?.comment}
            disabled={disabled}
            required={result?.outcome === InspectionOutcome.FAIL}
            onChange={(e) => handleCommentChange(question.id, e.target.value)}
          />
        )}
        {/* <FormHelperText>{helperText}</FormHelperText> */}
      </Box>
    );
  };

  const handleAllPassed = () => {
    questions.forEach((q) => {
      setAnswer(q.id, InspectionOutcome.PASS);
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    handleInspectionResultSubmit(item).finally(() => {
      setLoading(false);
      setExpanded(false);
      setInspectionDeviceId(undefined);
    });
  };

  useEffect(() => {
    getPhotos();
    getSignals();
  }, []);

  return (
    <form onSubmit={handleSubmit} key={`inspection_${result?.inspectionID}`}>
      <FormControl sx={{ m: 1, gap: '20px', width: '95%', border: `1px solid`, borderColor: getFillColor(), borderRadius: 0.8, p: 2 }} variant='standard'>
        <CollapsableContainer title={item.inspection?.name ?? 'UNKNOWN'} expanded={expanded} onClick={handleExpandClick}>
          {[...questions.filter((q) => q.deviceTypeID === null), ...questions.filter((q) => q.deviceTypeID === item?.device?.typeID)].map((q) =>
            RenderQuestion(q, getResult(q.id))
          )}
          <Divider sx={{ mb: 2 }} />
          {questions.length > 1 && (
            <>
              <Box display='flex' flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <FormLabel id={'outcome-label'}>Does this pass all questions above?</FormLabel>
                <Button disabled={disabled} size='small' variant='outlined' key='yes' onClick={handleAllPassed}>
                  Yes
                </Button>
              </Box>
              <Divider sx={{ mb: 2, mt: 2 }} />
            </>
          )}
          <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} gap={1} mt={1}>
            {/* <Button disabled size='small' variant='contained'>
              <CameraAlt />
            </Button> */}
            <UploadButton variant='contained' title={'Photo(s)'} accept='image/*' icon={<CameraAlt />} loading={importLoading} onClick={handleImport} />
            <LoadingButton loading={loading} disabled={!dirty} type='submit' variant='contained'>
              Submit
            </LoadingButton>
          </Box>

          {context?.hasSignals && (
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <Divider sx={{ mt: 2, mb: 1 }} />
              <FormLabel>Last remote: {getDateString(lastRemote, context?.timeZone)}</FormLabel>
              <FormLabel sx={{ fontWeight: 700 }}>Signal(s) raised:</FormLabel>
              <SignalList items={signals?.slice(0, 5)} />
            </Box>
          )}
          <ImageCarousel photos={photos} />
        </CollapsableContainer>
      </FormControl>
    </form>
  );
};
