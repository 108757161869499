import { Box, Button, Grid2 as Grid } from '@mui/material';
import MainCard from 'components/cards/MainCard';
import { gridSpacing } from 'config';
import useAppContext from 'hooks/useAppContext';
import { useEffect, useState } from 'react';
import SignalsTable from 'collections/SignalsTable';
import EventItemsTable from 'collections/EventItemsTable';
import { AppRoles, SystemState } from 'entities/enums';
import useConfirm from 'hooks/useConfirm';
import useSignals from 'hooks/useSignals';
import { Toggler } from 'components/shared/Toggler';
import InspectionsTable from 'collections/InspectionsTable';
import { inRole } from 'utils/extensions';
import { ClientTitle } from 'components/shared/ClientTitle';
import { MapContainer } from 'components/maps/MapContainer';
import { MapSearch } from 'components/maps/components/MapSearch';
import { MapSelect } from 'components/maps/components/MapSelect';
import { useWindowSize } from 'hooks/useWindowSize';
import { SignalStateVM } from 'entities/viewModels';
import useAxios from 'hooks/useAxios';
import useNotification from 'hooks/useNotification';
import { AppComponent } from 'components/apps/tma/AppComponent';

const Dashboard = () => {
  const { get, post } = useAxios();
  const { width } = useWindowSize();
  const { context, contact } = useAppContext();
  const { success, error } = useNotification();
  const { confirm, ConfirmDialog } = useConfirm();

  const [show, setShow] = useState('Active');
  const [systemId, setSystemId] = useState<number>();

  const [refresh, setRefresh] = useState<Symbol>();
  const [states, setStates] = useState<SignalStateVM[]>();

  const isMapViewer = inRole(contact?.role, [AppRoles.MapViewer]);
  const hasMultipleSystems = (contact?.systems?.length ?? 0) > 1;

  const handleSignalReset = async () => {
    if (context?.systemState !== SystemState.Normal) return;
    if ((await confirm('Are you sure you want to reset all active states?')) && systemId)
      post(`/api/signalstate/${systemId}/reset`).then((ok) => {
        if (ok) {
          setRefresh(Symbol());
          success('Successfully cleared all active signals');
        } else error('Failed to clear active signals');
      });
  };

  useEffect(() => {
    if (context?.systemId) {
      setSystemId(context?.systemId);
      get<SignalStateVM[]>(`/api/signalstate/${context.systemId}/active`).then(setStates);
    } else setStates(undefined);
  }, [context, context?.systemId]);

  return (
    <Grid spacing={gridSpacing} container>
      {isMapViewer ? (
        <Grid size={{ xs: 12 }}>
          {width!! <= 430 ? (
            <Box gap={2} flexDirection={'column'} display={'flex'}>
              <MapSearch />
              {!hasMultipleSystems && <MapSelect />}
              <MapContainer mapType='alarm' />
              {hasMultipleSystems && <MapSelect />}
            </Box>
          ) : (
            <MainCard title={<ClientTitle prefix={'Maps for'} showBuildingShortName showSystem />} secondary={<MapSearch width={250} />}>
              {/* <Box> */}
              <Box display={'flex'} justifyContent={'flex-end'} mb={1} gap={2}>
                <MapSelect width={250} />
                {/* <DateTimeRangePicker disabled /> */}
              </Box>
              <MapContainer mapType='view' height={'70vh'} />
            </MainCard>
          )}
        </Grid>
      ) : (
        <Grid size={{ xs: 12 }} container>
          {context?.hasSignals && (
            <Grid size={{ xs: 12, lg: 9 }}>
              <MainCard
                title={<ClientTitle prefix={`${show} Signals for`} showSiteCode showBuildingShortName showSystem />}
                secondary={
                  !inRole(contact?.role, [AppRoles.SystemViewer, AppRoles.MapViewer]) && (
                    <Grid container>
                      <Grid size={{ xs: 12, lg: 12 }}>
                        {show === 'Active' && (
                          <Button
                            sx={{ mr: 1 }}
                            disabled={(context?.systemState ?? SystemState.Normal) !== SystemState.Normal || (states?.length ?? 0) === 0}
                            color='warning'
                            variant='contained'
                            onClick={handleSignalReset}
                          >
                            Reset
                          </Button>
                        )}
                        <Toggler id='dashboard-state' values={[{ name: 'Active' }, { name: 'All' }]} value={show} onChange={setShow} />
                      </Grid>
                    </Grid>
                  )
                }
              >
                <ConfirmDialog />
                <SignalsTable showActions={true} refresh={refresh} type={show === 'Active' ? 'Active' : 'All'} />
              </MainCard>
            </Grid>
          )}
          <Grid size={{ xs: 12, lg: context?.hasSignals ? 3 : 12 }}>
            <Box gap={1} display={'flex'} flexDirection={'column'}>
              {inRole(contact?.role, [AppRoles.Admin, AppRoles.Inspector]) && (
                <MainCard title={'Inspections'}>
                  <InspectionsTable type='Active' pageSizeOptions={[5]} limited={true} disabled={true} hideHeader={true} />
                </MainCard>
              )}
              {/* <MainCard title={'Event Logs'}>
                <EventItemsTable pageSizeOptions={[5]} />
              </MainCard> */}
            </Box>
          </Grid>
        </Grid>
      )}
      {contact?.app && (
        <Grid key={contact?.app.id} size={{ xs: 12 }}>
          <MainCard title={contact?.app.name}>
            <AppComponent />
          </MainCard>
        </Grid>
      )}
    </Grid>
  );
};

export default Dashboard;
