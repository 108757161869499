import * as signalR from '@microsoft/signalr';
import { InspectionResult, Pulse, Signal, SignalState } from 'entities';
import { InspectionState } from 'entities/enums';
import { SystemState } from 'entities/enums/SystemState';
import { SignalStateVM } from 'entities/viewModels';
import logger from 'utils/logger';

const URL = `${process.env.REACT_APP_API_BASEURL}/hub`;

class BroadcastHelper {
  private connection: signalR.HubConnection;
  public systemStateHandler: (onSystemStateRaised: (state: { id: number; state: SystemState }) => void) => void;
  public inspectionStateHandler: (onInspectionStateRaised: (state: { id: number; state: InspectionState }) => void) => void;
  public inspectionResultHandler: (onInspectionResultRaised: (result: InspectionResult) => void) => void;
  public pulseHandler: (onPulseRaised: (signal: Pulse) => void) => void;
  public signalHandler: (onSignalRaised: (signal: Signal) => void) => void;
  public signalStateHandler: (onSignalStateRaised: (signalState: SignalStateVM) => void) => void;

  static instance: BroadcastHelper;
  constructor() {
    this.connection = new signalR.HubConnectionBuilder().withUrl(URL).withAutomaticReconnect().build();
    this.connection.start().catch((err) => logger.logError('FAILED to start BroadcastHelper:', err));
    // TODO make Map<BroadcastEvents>
    this.systemStateHandler = (onSystemStateRaised) => {
      this.connection.on('systemStateUpdate', (systemState: { id: number; state: SystemState }) => {
        logger.log(`Sysytem state BROADCAST: SYSTEM ID:${systemState.id} to ${SystemState[systemState.state]}`);
        onSystemStateRaised(systemState);
      });
    };
    this.inspectionStateHandler = (onInspectionStateRaised) => {
      this.connection.on('inspectionStateUpdate', (inspectionState: { id: number; state: InspectionState }) => {
        logger.log(`Inspection state BROADCAST: INSPECTION ID:${inspectionState.id} to ${InspectionState[inspectionState.state]}`);
        onInspectionStateRaised(inspectionState);
      });
    };
    this.inspectionResultHandler = (onInspectionResultRaised) => {
      this.connection.on('inspectionResultUpdate', (inspectionResult: InspectionResult) => {
        logger.log(`InspectionResult BROADCAST: ${inspectionResult.id}`);
        onInspectionResultRaised(inspectionResult);
      });
    };
    this.pulseHandler = (onPulseRaised) => {
      this.connection.on('pulseSend', (signal: Pulse) => {
        logger.log(`Pulse BROADCAST: ${signal.id}`);
        onPulseRaised(signal);
      });
    };
    this.signalHandler = (onSignalRaised) => {
      this.connection.on('signalRaised', (signal: Signal) => {
        logger.log(`Signal BROADCAST: ${signal.id}`);
        onSignalRaised(signal);
      });
    };
    this.signalStateHandler = (onSignalStateRaised) => {
      this.connection.on('signalStateUpdate', (signalState: SignalStateVM) => {
        logger.log(`SignalState BROADCAST: ${signalState.id}`);
        onSignalStateRaised(signalState);
      });
    };
  }
  // not needed, send from api updates
  // public notify = (state: AppState) => {
  //     this.connection.send("stateUpdate", state).then(x => logger.log("Notified StateUpdate:", state))
  // }
  public static getInstance(): BroadcastHelper {
    if (!BroadcastHelper.instance) BroadcastHelper.instance = new BroadcastHelper();
    return BroadcastHelper.instance;
  }
}

export default BroadcastHelper.getInstance;
