import { Button } from '@mui/material';
import MainCard from 'components/cards/MainCard';
import { ClientTitle } from 'components/shared/ClientTitle';
import AddIcon from '@mui/icons-material/Add';
import { useLoaderData, useNavigate } from 'react-router-dom';
import BuildingsTable from 'collections/BuildingsTable';
import { Building } from 'entities';
import { useEffect, useState } from 'react';
import useAxios from 'hooks/useAxios';
import { BuildingForm } from 'forms/BuildingForm';
import Loader from 'components/shared/Loader';
import { SubmitHandler } from 'react-hook-form';
import logger from 'utils/logger';
import { GridColDef } from '@mui/x-data-grid';
import { EditTableCellAction } from 'components/actions/EditTableCellAction';
import { AppRoles } from 'entities/enums';
import { BuildingVM } from 'entities/viewModels';

export const loader = ({ params }: any) => params?.id?.toString() ?? '';

export const BuildingsView = () => {
  const params = useLoaderData() as string;

  const navigate = useNavigate();
  const { get, post } = useAxios();

  const [id, setId] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [entity, setEntity] = useState<Building>();

  const actions: GridColDef<BuildingVM>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return EditTableCellAction(params, (id) => setId(id), [AppRoles.Admin]);
      },
    },
  ];

  const resetForm = () => {
    setId(undefined);
    setShowForm(false);
    setEntity(undefined);
  };

  const handleSubmit: SubmitHandler<Building> = (data) => {
    logger.log('Building SUBMIT', data);
    //post<Building>(`/api/Building`, data).then(() => navigate('/'));
    setShowForm(false);
  };

  useEffect(() => {
    if (id && id > 0) {
      get<Building>(`/api/building/${id}`).then((e) => {
        setEntity(e);
        setShowForm(true);
      });
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    // methods.reset(InitialState);
    if (!isNaN(parseInt(params))) setId(parseInt(params));
  }, [params]);

  if (loading) return <Loader />;

  return (
    <MainCard
      title={showForm ? entity ? `Building: ${entity.name}` : 'New Building' : <ClientTitle prefix={'Buildings for'} showClient />}
      secondary={
        !showForm && (
          <Button
            variant='outlined'
            startIcon={<AddIcon />}
            onClick={() => {
              navigate('/buildings/0');
              setShowForm(true);
            }}
          >
            Add Building
          </Button>
        )
      }
    >
      {showForm ? <BuildingForm entity={entity} onSubmit={handleSubmit} onCancel={resetForm} /> : <BuildingsTable actions={actions} />}
    </MainCard>
  );
};
