import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Checkbox, Divider, FormControlLabel, Grid2 as Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useMaps from 'hooks/useMaps';
import useAxios from 'hooks/useAxios';
import useConfirm from 'hooks/useConfirm';
import useNotification from 'hooks/useNotification';
import { MapOverlay } from 'entities';

interface BoundProps {
  min?: number;
  max?: number;
  step: number;
  name: string;
  disabled: boolean;
}

interface BoundInputProps extends BoundProps {
  value: number;
  onChange: (value: number, name: string) => void;
}

const BoundInput = ({ value, name, min, max, step, disabled, onChange }: BoundInputProps) => {
  // const [_value, setValue] = React.useState(0);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value === '' ? 0 : Number(event.target.value), name);
  };

  return (
    <>
      <TextField
        key={name}
        size='small'
        label={`${name} border`}
        value={value}
        disabled={disabled}
        onChange={handleInputChange}
        sx={{ width: 100 }}
        inputProps={{
          step: step,
          min: min ?? -1000,
          max: max ?? 1000,
          type: 'number',
        }}
      />
    </>
  );
};

export const MapBounds = () => {
  const { get, patch } = useAxios();
  const { success, error } = useNotification();
  const { mapOverlay, setMapOverlay } = useMaps();
  const { confirm, ConfirmDialog } = useConfirm();

  const [overlay, setOverlay] = React.useState<MapOverlay>();

  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [fracStep, setFracStep] = React.useState(true);

  const onReset = () => {
    if (!overlay) return;
    setLoading(true);
    confirm('Are you sure you want to reset to the previous bounds?').then((yes) => {
      if (yes) {
        get<MapOverlay>(`/api/mapoverlays/${overlay.id}`).then(setMapOverlay);
      }
      setLoading(false);
    });
  };

  const onSubmit = () => {
    if (!overlay) return;
    setLoading(true);
    confirm('Are you sure you want to update these bounds?').then((yes) => {
      if (yes) {
        patch<MapOverlay>(`/api/maplayers/${overlay.id}`, overlay).then((ok) => {
          if (ok) success('Map bounds updated');
          else error('Failed to update map bounds');
          setLoading(false);
        });
      }
      setLoading(false);
    });
  };

  const handleChange = (value: number, name: string) => {
    if (mapOverlay) setMapOverlay({ ...mapOverlay, [name.toLocaleLowerCase()]: value });
  };

  React.useEffect(() => setOverlay(mapOverlay), [mapOverlay]);

  if (overlay === undefined) return <>No overlay set for bounds</>;

  return (
    overlay && (
      <Box display={'flex'} gap={2} flexDirection={'column'} sx={{ maxWidth: 300 }}>
        <ConfirmDialog />
        <Box display={'flex'} gap={1} flexDirection={'column'}>
          <Box display={'flex'} justifyContent={'center'} flexGrow={1}>
            <BoundInput value={overlay.north} onChange={handleChange} step={fracStep ? 0.1 : 1} name={'North'} key={'North'} disabled={disabled} />
          </Box>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <BoundInput value={overlay.west} onChange={handleChange} step={fracStep ? 0.1 : 1} name={'West'} key={'West'} disabled={disabled} />
            <BoundInput value={overlay.east} onChange={handleChange} step={fracStep ? 0.1 : 1} name={'East'} key={'East'} disabled={disabled} />
          </Box>
          <Box display={'flex'} justifyContent={'center'}>
            <BoundInput value={overlay.south} onChange={handleChange} step={fracStep ? 0.1 : 1} name={'South'} key={'South'} disabled={disabled} />
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'row'} gap={1}>
          <FormControlLabel control={<Checkbox size='small' disabled />} label={'Lock aspect ratio'} />
          <FormControlLabel control={<Checkbox size='small' checked={fracStep} onChange={(_, c) => setFracStep(c)} />} label={'Fractional step'} />
        </Box>
        {/* <Box component='div' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', gap: 2 }}>
          <Button type='button' variant='outlined' onClick={onReset}>
            Reset
          </Button>
          <LoadingButton loading={loading} variant='contained' onClick={onSubmit}>
            Save
          </LoadingButton>
        </Box> */}
      </Box>
    )
  );
};
