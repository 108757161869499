import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useAppContext from 'hooks/useAppContext';
import AnimateButton from 'components/extended/AnimateButton';
import useScriptRef from 'hooks/useScriptRef';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const LoginForm = ({ loginProp, ...others }: { loginProp?: number }) => {
  const theme = useTheme();
  const { feedback, login } = useAppContext();

  const scriptedRef = useScriptRef();

  const [checked, setChecked] = React.useState(true);

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault()!;
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().max(255).required('Email/Username is required'),
        password: Yup.string().max(255).required('Password is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await login(values.email, values.password);

          if (scriptedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err: any) {
          console.error(err);
          if (scriptedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: 'Failed to login, please try again later or contact support' });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit} {...others}>
          <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
            <InputLabel htmlFor='outlined-adornment-email-login'>Email Address / Username</InputLabel>
            <OutlinedInput id='outlined-adornment-email-login' type='email' value={values.email} name='email' onBlur={handleBlur} onChange={handleChange} inputProps={{}} />
            {touched.email && errors.email && (
              <FormHelperText error id='standard-weight-helper-text-email-login'>
                {errors.email}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth error={Boolean(touched.password && errors.password)} sx={{ ...theme.typography.customInput }}>
            <InputLabel htmlFor='outlined-adornment-password-login'>Password</InputLabel>
            <OutlinedInput
              id='outlined-adornment-password-login'
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              name='password'
              onBlur={handleBlur}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end' size='large'>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{}}
              label='Password'
            />
            {touched.password && errors.password && (
              <FormHelperText error id='standard-weight-helper-text-password-login'>
                {errors.password}
              </FormHelperText>
            )}
          </FormControl>

          <Grid container alignItems='center' justifyContent='space-between'>
            <Grid>
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={(event) => setChecked(event.target.checked)} name='checked' color='primary' />}
                label='Keep me logged in'
              />
            </Grid>
            <Grid>
              <Typography variant='subtitle1' component={Link} to={'/forgot'} color='secondary' sx={{ textDecoration: 'none' }}>
                Forgot Password?
              </Typography>
            </Grid>
          </Grid>

          {(errors.submit || feedback) && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit || feedback}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <AnimateButton>
              <Button color='secondary' disabled={isSubmitting} fullWidth size='large' type='submit' variant='contained'>
                Sign In
              </Button>
            </AnimateButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
