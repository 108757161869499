import { GridColDef } from '@mui/x-data-grid';
import { DataGrid, DataGridActionProps } from 'components/dataGrid/DataGrid';
import { Incident } from 'entities';
import useAppContext from 'hooks/useAppContext';
import { useState, useEffect } from 'react';
import { getDateString } from 'utils/dateHelper';

interface IncidentsTableActions extends DataGridActionProps<Incident> {}

interface IncidentTableProps extends IncidentsTableActions {
  pageSizeOptions?: number[];
}

export default function IncidentsTable({ actions, pageSizeOptions }: IncidentTableProps) {
  const { context } = useAppContext();
  const [dataUrl, setDataUrl] = useState<string>();
  const columns: GridColDef<Incident>[] = [
    {
      field: 'date',
      headerName: 'Date',
      filterable: false,
      width: 200,
      renderCell(params) {
        return <>{getDateString(params.value, context?.timeZone)}</>;
      },
    },
    {
      field: 'name',
      headerName: 'Incident',
      filterable: false,
      width: 300,
    },
  ];

  useEffect(() => {
    setDataUrl(`/api/incident/query/${context?.systemId}`);
  }, [context?.systemId]);

  return (
    <DataGrid
      id={'incidentsTable'}
      dataUrl={dataUrl}
      hideHeader={true}
      orderBy={'date desc'}
      pageSizeOptions={pageSizeOptions}
      columnDefinitions={actions ? [...actions, ...columns] : columns}
    />
  );
}
