import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, SvgIconTypeMap, Typography } from '@mui/material';
import { ReactNode } from 'react';

type UploadButtonProps = {
  accept: string;
  title: string;
  loading: boolean;
  subtitle?: string;
  variant?: 'text' | 'outlined' | 'contained';
  icon: ReactNode;
  onClick: (files: FileList | null) => Promise<void>;
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const UploadButton = ({ loading, title, subtitle, accept, variant, icon, onClick }: UploadButtonProps) => {
  return (
    <LoadingButton size='small' component='label' loading={loading} variant={variant ?? 'outlined'} startIcon={icon} sx={{ textTransform: 'none' }}>
      <Box display={'flex'} flexDirection={'column'}>
        {title}
        {subtitle && !loading && <Typography variant='caption'>{subtitle}</Typography>}
      </Box>
      <VisuallyHiddenInput
        accept={accept}
        type='file'
        onChange={(event) => {
          onClick(event.target.files).then(() => (event.target.value = ''));
        }}
        multiple
      />
    </LoadingButton>
  );
};
