// material-ui
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import logoDark from 'assets/images/logo-dark.png';
import logo from 'assets/images/logo.png';
import { ThemeMode } from 'types/theme';
import useAppContext from 'hooks/useAppContext';
import { ClientTitle } from './ClientTitle';
import { Badge, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_PATH } from 'config';

type LogoProps = {
  hideSystem?: boolean;
  showBadge?: boolean;
};

const Logo = ({ showBadge, hideSystem }: LogoProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { context, contact } = useAppContext();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', ':hover': { cursor: 'pointer' } }}>
      <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt='Beacon Suite Logo' height='40' width='auto' onClick={() => navigate(DASHBOARD_PATH)} />
      <ListItemText
        primary={
          showBadge ? (
            <Badge badgeContent={'BETA'} color='warning' onClick={() => navigate('/changelog')}>
              <Typography variant={'h3'} color='inherit' onClick={() => navigate(DASHBOARD_PATH)}>
                Beacon Suite
              </Typography>
            </Badge>
          ) : (
            <Typography variant={'h3'} color='inherit' onClick={() => navigate(DASHBOARD_PATH)}>
              Beacon Suite
            </Typography>
          )
        }
        secondary={
          context &&
          contact &&
          !hideSystem && (
            <Typography noWrap={true} variant='caption' sx={{ ...theme.typography.subMenuCaption, mt: 0.2 }} display='block' gutterBottom onClick={() => navigate(DASHBOARD_PATH)}>
              <ClientTitle showSiteCode showBuildingShortName showSystem />
            </Typography>
          )
        }
      />
    </Box>
  );
};

export default Logo;
