import { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Divider } from '@mui/material';
import useMaps from 'hooks/useMaps';
import useAppContext from 'hooks/useAppContext';
import { LayersMap } from 'contexts/MapContext';
import useLocalStorage from 'hooks/useLocalStorage';
import { AppConfig, InitialAppConfig } from 'entities/base/AppConfig';

type MapSelectProps = {
  width?: number;
  nativeCSS?: React.CSSProperties;
};

export const MapSelect = ({ width, nativeCSS }: MapSelectProps) => {
  const { context } = useAppContext();
  const [selected, setSelected] = useState<number>();
  const { map, maps, mapItem, layers, setMap, setLayers } = useMaps();

  // remember last map used for system
  const [mapState, setMapState] = useState<Map<number, number>>();
  const [config, setConfig] = useLocalStorage<AppConfig>('bs-config', InitialAppConfig);

  const mapOverlay = map?.overlays?.find((l) => l.name.startsWith('layers'));

  const handleSelectChange = (value: string | number) => {
    const id = parseInt(value.toString());
    if (!isNaN(id)) {
      setMap(id);
      setSelected(id);
      if (id && mapState && context?.systemId) {
        mapState.set(context.systemId, id);
        setConfig({ ...config, mapStates: JSON.stringify(Array.from(mapState.entries())) });
      }
    } else {
      layers[value] = !layers[value];
      setLayers(layers);
    }
  };

  useEffect(() => {
    if (map?.id) {
      setSelected(map?.id);
      const _layers: LayersMap = {};
      mapOverlay?.layers?.forEach((o) => (_layers[o.tagPrefix] = o.enabled));
      setLayers(_layers);
    }
  }, [map, map?.id]);

  useEffect(() => {
    const _mapState = config.mapStates ? new Map<number, number>(JSON.parse(config.mapStates)) : new Map<number, number>();

    const _mapId = _mapState.get(context?.systemId ?? -1) ?? maps?.at(0)?.mapID;
    if (_mapId && context?.systemId && !mapItem) {
      _mapState.set(context.systemId, _mapId);
      setMap(_mapId);
      setSelected(_mapId);
    }

    setMapState(_mapState);
    // async function load() {
    //   await setMap(maps?.find((m) => m.id === 1)?.id ?? maps?.at(0)?.id);
    // }
    // if (!map) load();
  }, []);

  if (nativeCSS)
    return (
      <select disabled={maps?.length === 0} value={map?.id} onChange={(e) => handleSelectChange(e.target.value)} style={nativeCSS}>
        <optgroup label='Maps'>
          {maps?.map((m) => (
            <option key={m.mapID} value={m.mapID}>
              {m.name}
            </option>
          ))}
        </optgroup>
        {(mapOverlay?.layers.length ?? 0) > 0 && (
          <optgroup label='Layers'>
            {mapOverlay?.layers
              ?.filter((o) => o.showInMenu)
              ?.sort((a, b) => a.id - b.id)
              .map((o) => <option key={o.tagPrefix} value={o.tagPrefix}>{`${layers[o.tagPrefix] ? 'Hide' : 'Show'} ${o.name}`}</option>)}
          </optgroup>
        )}
      </select>
    );

  return (
    <FormControl size='small' sx={width ? { width: width } : {}} fullWidth={!width} disabled={maps?.length === 0}>
      <InputLabel id='map-label'>Select Map</InputLabel>
      <Select labelId='map-label' id='map-select' value={selected ?? ''} label='Select Map' onChange={(e) => handleSelectChange(e.target.value)}>
        {maps?.map((m) => (
          <MenuItem key={m.mapID} value={m.mapID}>
            {m.name}
          </MenuItem>
        ))}
        {(mapOverlay?.layers.length ?? 0) > 0 && <Divider />}
        {mapOverlay?.layers
          ?.filter((o) => o.showInMenu)
          ?.sort((a, b) => a.id - b.id)
          .map((o) => <MenuItem key={o.tagPrefix} value={o.tagPrefix}>{`${layers[o.tagPrefix] ? 'Hide' : 'Show'} ${o.name}`}</MenuItem>)}
      </Select>
    </FormControl>
  );
};
