import * as React from 'react';
import { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { leadingZeros } from 'utils/extensions';
import { Pulse } from 'entities/Pulse';
import useAxios, { QueryOptions } from 'hooks/useAxios';
import { Pagination } from 'entities/base/Pagination';
import BroadcastHelper from 'helpers/BroadcastHelper';
import useAppContext from 'hooks/useAppContext';
import { MonitorHeartOutlined } from '@mui/icons-material';
import { getDateString } from 'utils/dateHelper';

function RenderPulse(pulse: Pulse) {
  const { context } = useAppContext();
  return (
    <Box key={pulse.id}>
      <ListItem alignItems='flex-start'>
        <ListItemAvatar>
          <Avatar alt='Pulse'>
            <MonitorHeartOutlined />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography sx={{ display: 'inline', mr: 2 }} component='span' variant='body2' color='text.primary'>
                {`#${leadingZeros(pulse.id, 4)}`}
              </Typography>
              {pulse.name}
            </React.Fragment>
          }
          secondary={
            <>
              {getDateString(pulse.date, context?.timeZone)}
              <br />
              {pulse.message}
            </>
          }
        />
      </ListItem>
      <Divider variant='inset' component='li' />
    </Box>
  );
}

export default function PulseList() {
  const { getPaged } = useAxios();
  const { context } = useAppContext();
  const { pulseHandler } = BroadcastHelper();

  const [pulses, setPulses] = useState<Pulse[]>();
  const [pagination, setPagination] = useState<Pagination>();

  const fetchPulses = () => {
    const options: QueryOptions = {
      pageSize: 10,
      page: 0,
      orderBy: 'date desc',
    };
    getPaged<Pulse[]>(`/api/pulse/query/${context?.systemId}`, options).then((r) => {
      if (r) {
        setPulses(r?.items);
        setPagination(r.pagination);
      }
    });
  };

  useEffect(() => {
    pulseHandler(fetchPulses);
  });

  useEffect(() => {
    fetchPulses();
  }, []);

  return (
    <Box>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>{pulses?.map(RenderPulse)}</List>
      {pagination && pagination.totalCount > pagination.pageSize && `Showing ${pagination.pageSize ?? 0} from ${pagination.totalCount}`}
    </Box>
  );
}
