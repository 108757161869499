import { OptionsObject, enqueueSnackbar } from 'notistack';
import { ReactElement } from 'react';

const useNotification = () => {
  const error = (message: string, options?: OptionsObject<'error'>) => enqueueSnackbar(message, { variant: 'error', ...options });
  const info = (message: string, options?: OptionsObject<'info'>) => enqueueSnackbar(message, { variant: 'info', ...options });
  const success = (message: string, options?: OptionsObject<'success'>) => enqueueSnackbar(message, { variant: 'success', ...options });
  const warning = (message: string, options?: OptionsObject<'warning'>) => enqueueSnackbar(message, { variant: 'warning', ...options });
  const notify = (html: ReactElement, options?: OptionsObject) => enqueueSnackbar(html, { ...options });

  return { error, info, success, warning, notify };
};

export default useNotification;
