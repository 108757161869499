import { Box, Grid2 as Grid, IconButton, ListItem, Stack, Typography } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import useNotification from '../hooks/useNotification';
import { DataGrid } from '../components/dataGrid/DataGrid';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { CloudUpload, InfoRounded } from '@mui/icons-material';
import useAppContext from 'hooks/useAppContext';
import MainCard from 'components/cards/MainCard';
import { Entity, NamedEntity } from 'entities/base/Entity';
import { SignalList } from 'collections/SignalList';
import { DeviceVM, SignalVM } from 'entities/viewModels';
import SubCard from 'components/cards/SubCard';
import useAxios from 'hooks/useAxios';
import { Device } from 'entities';
import { DataType, SystemState } from 'entities/enums';
import { UploadButton } from 'components/shared/UploadButton';
import { useNavigate } from 'react-router-dom';
import { CollapsableContainer } from 'components/shared/CollapsableContainer';
import { getDateString } from 'utils/dateHelper';
import { DeviceList } from 'collections/DeviceList';

interface ReportSystemTest extends NamedEntity {
  date: string;
  end?: string;
  devices: number;
  signals: number;
  tests: number;
}

interface ReportLastSignal extends NamedEntity {
  date?: string;
  address?: string;
  remoteDate?: string;
  zone?: string;
  exception: boolean;
}

export const ReportSignals = () => {
  const navigate = useNavigate();
  const { context } = useAppContext();
  const { get, postFile } = useAxios();
  const { notify, error } = useNotification();

  // signal selected properties
  const [name, setName] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [zone, setZone] = useState<string>();
  const [signals, setSignals] = useState<SignalVM[]>();
  const [lastLocal, setLastLocal] = useState<string>();
  const [lastRemote, setLastRemote] = useState<string>();

  const [devices, setDevices] = useState<DeviceVM[]>();

  const [reload, setReload] = useState<Symbol>();
  const [importLoading, setImportLoading] = useState(false);
  const [importDate, setImportDate] = useState(context?.lastImport);

  const handleImport = async (files: FileList | null) => {
    if (files && files.length > 0) {
      setImportLoading(true);
      var result = await postFile(`/api/data/${context?.siteId}/Upload/${DataType.Scarsdale}`, files);
      if (result.ok) {
        var strings = result.message.split('\r\n');
        notify(
          <Box display={'flex'} flexDirection={'column'}>
            {'Upload successfull'}
            {strings.map((s, k) => (
              <Typography key={k} variant='caption'>
                {s}
              </Typography>
            ))}
          </Box>,
          { variant: 'success' }
        );
        setReload(Symbol());
        setImportDate(new Date().toISOString());
      } else error('Failed to upload');
      setImportLoading(false);
    }
  };

  const getDevices = (item?: ReportSystemTest) => {
    setName(item?.name);
    setDevices(undefined);
    if (item) get<DeviceVM[]>(`/api/reports/SystemTestItems/${context?.systemId}/${item.id}`).then(setDevices);
  };

  const getSignals = (item?: ReportLastSignal) => {
    setName(item?.name);
    setAddress(item?.address);
    setZone(item?.zone);
    setSignals(undefined);
    setLastLocal(item?.date);
    setLastRemote(item?.remoteDate);
    if (item) get<SignalVM[]>(`/api/reports/systemSignalItems/${context?.systemId}/${item.id}`).then(setSignals);
  };

  const RenderSignalActionCell = (item: ReportLastSignal): ReactNode => {
    return (
      <Stack direction='row' spacing={1} height={'100%'} alignItems='center'>
        <IconButton size='small' aria-label='edit' color='inherit' onClick={() => getSignals(item)}>
          <InfoRounded />
        </IconButton>
      </Stack>
    );
  };

  const activationColumns: GridColDef<ReportLastSignal>[] = [
    {
      field: 'edit',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return RenderSignalActionCell(params.row);
      },
    },
    {
      field: 'name',
      headerName: 'Device',
      width: 400, //150,
    },
    {
      field: 'exception',
      headerName: 'EXCEPTION',
      width: 150,
    },

    {
      field: 'date',
      headerName: 'Last Signal Date',
      width: 250,
      filterable: true,
      renderCell(params) {
        return getDateString(params.value, context?.timeZone);
      },
    },
    {
      field: 'remoteDate',
      headerName: 'Last Remote Date',
      width: 250,
      filterable: true,
      renderCell(params) {
        return getDateString(params.value, context?.timeZone);
      },
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 120, //150,
    },
    {
      field: 'zone',
      headerName: 'CS Zone',
      width: 100,
    },
  ];

  const RenderTestActionCell = (params: GridRenderCellParams<ReportSystemTest, any, any, GridTreeNodeWithRender>): ReactNode => {
    return (
      <Stack direction='row' spacing={1} height={'100%'} alignItems='center'>
        <IconButton
          size='small'
          aria-label='edit'
          color='inherit'
          onClick={() => {
            setDevices(undefined);
            get<DeviceVM[]>(`/api/reports/systemTestItems/${context?.systemId}/${params.row.id}`).then(setDevices);
          }}
        >
          <InfoRounded />
        </IconButton>
      </Stack>
    );
  };

  const testColumns: GridColDef<ReportSystemTest>[] = [
    {
      field: 'edit',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: RenderTestActionCell,
    },
    {
      field: 'date',
      headerName: 'Start Date',
      width: 250,
      filterable: true,
      renderCell(params) {
        return getDateString(params.value, context?.timeZone);
      },
    },
    {
      field: 'end',
      headerName: 'End Date',
      width: 250,
      filterable: true,
      renderCell(params) {
        if (params.value) {
          return getDateString(params.value, context?.timeZone);
        }

        return '-';
      },
    },
    {
      field: 'devices',
      headerName: 'Devices',
      width: 200, //150,
    },
    {
      field: 'signals',
      headerName: 'Signals',
      width: 200, //150,
    },
    // {
    //   field: 'tests',
    //   headerName: 'Tests',
    //   width: 150,
    // },
  ];

  const RenderListItemTemplateSignals = (entity: Entity) => {
    const item = entity as ReportLastSignal;
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {
      getSignals(expanded ? item : undefined);
    }, [expanded]);

    return (
      <CollapsableContainer
        children={<SignalList items={signals /*?.slice(0, 8)*/} noDataText={name ? 'No signals raised' : 'Click info button to show last signals'} />}
        header={
          <Box display={'flex'} flexDirection={'column'} m={1}>
            <Typography variant='h5'>{item.name}</Typography>
            <Typography variant='caption'>{`Address: ${item.address} [${item.zone}]`}</Typography>
            <Typography variant='caption'>{`Last Signal: ${getDateString(item.date)}`}</Typography>
            <Typography variant='caption'>{`Last Remote: ${getDateString(item.remoteDate)}`}</Typography>
          </Box>
        }
        expanded={expanded}
        onClick={() => setExpanded(!expanded)}
      ></CollapsableContainer>
    );
  };

  const RenderListItemTemplateDevices = (entity: Entity) => {
    const item = entity as ReportSystemTest;
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {
      getDevices(expanded ? item : undefined);
    }, [expanded]);

    return (
      <CollapsableContainer
        children={<DeviceList items={devices /*?.slice(0, 8)*/} noDataText={name ? 'No devices found' : 'Click info button to show tested devices'} />}
        header={
          <Box display={'flex'} flexDirection={'column'} m={1}>
            <Typography variant='h5'>{item.name}</Typography>
            <Typography variant='caption'>{`Start date: ${getDateString(item.date)}`}</Typography>
            <Typography variant='caption'>{`End date: ${getDateString(item.end)}`}</Typography>
          </Box>
        }
        expanded={expanded}
        onClick={() => setExpanded(!expanded)}
      ></CollapsableContainer>
    );
  };

  useEffect(() => {
    if (!context?.hasSignals) navigate('/dashboard');
  }, [context?.hasSignals]);

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <MainCard
          title={'System Signals'}
          secondary={
            <UploadButton
              title={'Import CS Signals'}
              accept='.csv'
              loading={importLoading}
              icon={<CloudUpload />}
              onClick={handleImport}
              subtitle={getDateString(importDate, context?.timeZone)}
            />
          }
        >
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 9 }}>
              <DataGrid
                reload={reload}
                // pageSizeOptions={[10]}
                // autoHeight={false}
                disableRowSelect={false}
                columnDefinitions={activationColumns}
                dataUrl={`/api/reports/systemSignals/${context?.systemId}`}
                orderBy='date asc'
                listItemTemplate={RenderListItemTemplateSignals}
              />
            </Grid>
            <Grid
              size={{ md: 3 }}
              sx={{
                display: { xs: 'none', md: 'block' },
              }}
            >
              <SubCard title={name} sx={{ width: '100%' }}>
                {address && (
                  <Typography mb={1} variant='h5'>
                    Address: {address}
                  </Typography>
                )}
                {zone && (
                  <Typography mb={1} variant='h5'>
                    CS Zone: {zone}
                  </Typography>
                )}
                {lastLocal && (
                  <Typography mb={1} variant='h5'>
                    Last local signal: {getDateString(lastLocal, context?.timeZone)}
                  </Typography>
                )}
                {lastRemote && (
                  <Typography mb={1} variant='h5'>
                    Last remote signal: {getDateString(lastRemote, context?.timeZone)}
                  </Typography>
                )}
                <SignalList items={signals /*?.slice(0, 8)*/} noDataText={name ? 'No signals raised' : 'Click info button to show last signals'} />
              </SubCard>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <MainCard title={'System Tests'}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 9 }}>
              <DataGrid
                autoHeight={false}
                disableRowSelect={false}
                columnDefinitions={testColumns}
                orderBy='date desc'
                listItemTemplate={RenderListItemTemplateDevices}
                dataUrl={`/api/reports/systemTests/${context?.systemId}`}
              />
            </Grid>
            <Grid
              size={{ xs: 12, md: 3 }}
              // sx={{
              //   display: { xs: 'none', md: 'block' },
              // }}
            >
              <SubCard title={'Tested Devices'} sx={{ width: '100%' }}>
                <DeviceList items={devices /*?.slice(0, 8)*/} noDataText={name ? 'No devices found' : 'Click info button to show tested devices'} />
              </SubCard>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  );
};
