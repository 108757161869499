import { Divider, Typography, Box } from '@mui/material';
import { DeviceVM } from 'entities/viewModels';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { InspectionResultForm } from './InspectionResultForm';
import { useEffect, useState } from 'react';
import useInspection from 'hooks/useInspection';
import useAxios from 'hooks/useAxios';
import useAppContext from 'hooks/useAppContext';
import { useWindowSize } from 'hooks/useWindowSize';

type InspectionsFormProps = {
  disabled?: boolean;
};

export const InspectionsForm = ({ disabled }: InspectionsFormProps) => {
  const { get } = useAxios();
  const { context } = useAppContext();

  const { width } = useWindowSize();
  const { type, inspectionId, inspectionDeviceId } = useInspection();

  const [data, setData] = useState<DeviceVM>();

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (width) setIsMobile(width < 450);
  }, [width]);

  useEffect(() => {
    if (inspectionDeviceId && type) get<DeviceVM>(`/api/device/${context?.systemId}/${type}/${inspectionDeviceId}${inspectionId ? `/${inspectionId}` : ''}`).then(setData);
    else setData(undefined);
  }, [inspectionDeviceId]);

  return (
    <Box sx={{ m: 1, gap: '20px', width: '95%' }}>
      <Box gap={1} display={'flex'} flexDirection={'column'}>
        <Typography sx={{ fontWeight: 700 }}>{data?.name}</Typography>
        {data?.type?.name && <Typography sx={{ fontSize: 'small' }}>Type: {data?.type?.name}</Typography>}
        {data?.address && <Typography sx={{ fontSize: 'small' }}>Address: {data?.address}</Typography>}
        {data?.barCode && <Typography sx={{ fontSize: 'small' }}>Barcode: {data?.barCode}</Typography>}
        <Divider />
        {(data?.inspectionResults?.length ?? 0) === 0 && <>No in-progress inspection(s) found.</>}
      </Box>

      <PerfectScrollbar
        aria-hidden='false'
        options={{ swipeEasing: true }}
        style={{
          overflowX: 'hidden',
          height: isMobile ? '60vh' : '80vh',
        }}
      >
        {data?.inspectionResults
          ?.sort((a, b) => new Date(a.dueDate!!).getTime() - new Date(b.dueDate!!).getTime())
          ?.map((i, k) => <InspectionResultForm lastRemote={data.lastRemoteDate} result={i} key={k} />) ?? <></>}
      </PerfectScrollbar>
    </Box>
  );
};
