import { GridColDef } from '@mui/x-data-grid';
import { DataGrid } from 'components/dataGrid/DataGrid';
import useAppContext from 'hooks/useAppContext';
import { getDateString } from 'utils/dateHelper';

export default function PhotosTable() {
  const { context } = useAppContext();

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      filterable: false,
      width: 200,
      renderCell(params) {
        return <>{getDateString(params.value, context?.timeZone)}</>;
      },
    },
    {
      field: 'name',
      headerName: 'Description',
      filterable: false,
      width: 300,
    },
  ];

  return <DataGrid id={'photosTable'} hideHeader={false} orderBy={'date desc'} columnDefinitions={columns} />;
}
