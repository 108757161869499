import { Box, IconButton, Stack } from '@mui/material';
import { ReactNode, useState } from 'react';
import logger from '../utils/logger';
import useNotification from '../hooks/useNotification';
import { DataGrid } from '../components/dataGrid/DataGrid';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { CloudDownload } from '@mui/icons-material';
import useAppContext from 'hooks/useAppContext';
import InspectionsTable from 'collections/InspectionsTable';
import MainCard from 'components/cards/MainCard';

function RenderActionCell(params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>): ReactNode {
  const handleExport = (id: number) => {
    logger.log('handleExport(%s)', id);
  };

  return (
    <Stack direction='row' spacing={1} height={'100%'} alignItems='center'>
      <IconButton disabled size='small' aria-label='edit' color='inherit' onClick={() => handleExport(params.row.id)}>
        <CloudDownload />
      </IconButton>
    </Stack>
  );
}

export const ReportInspections = () => {
  const columns: GridColDef[] = [
    {
      field: 'edit',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: RenderActionCell,
    },
    {
      field: 'name',
      headerName: 'Inspection',
      filterable: false,
      width: 400,
      disableColumnMenu: true,
    },
    {
      field: 'dueDate',
      headerName: 'Date',
      filterable: false,
      sortable: false,
      width: 135,
      disableColumnMenu: true,
    },
  ];

  return (
    <MainCard title={'Completed Inspections'}>
      <InspectionsTable columns={columns} type='Completed' noDataMessage='No ispection reports available' />
    </MainCard>
  );
};
