import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GuardProps } from 'types';
import useAppContext from 'hooks/useAppContext';
import { AuthRouteChild, AuthRoutes } from 'AppRoutes';
import Loader from 'components/shared/Loader';
import { inRole } from 'utils/extensions';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, contact } = useAppContext();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('login', { replace: true, state: { from: location } });
      return;
    }

    if (location.pathname === '/dashboard') return; // quick escape

    // TODO make public available for some entities like device (READ)
    if (!(AuthRoutes.children as AuthRouteChild[]).some((c) => inRole(contact?.role, c.roles) && (location.pathname + '/').indexOf((c.path ?? '').split(':')[0]) === 0)) {
      navigate('/dashboard', { replace: true });
    }
  }, [isLoggedIn, navigate, location.pathname]);

  return isLoggedIn ? children : <Loader />;
};

export default AuthGuard;
