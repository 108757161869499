import { SVGOverlay, useMap } from 'react-leaflet';
import { useEffect, useRef, useState } from 'react';
import useAppContext from 'hooks/useAppContext';
import { LatLngBounds, SVGOverlay as LeafletSVGOverlay } from 'leaflet';
import useAxios from 'hooks/useAxios';
import { MAPCENTER, MapType, MINZOOM } from './MapContainer';
import useMaps from 'hooks/useMaps';
import { renderLayerItems } from 'helpers/LayerHelper';
import { MapOverlay } from 'entities';
import useNotification from 'hooks/useNotification';

type MapOverlayComponentProps = {
  mapType: MapType;
  overlay: MapOverlay;
};

export function MapOverlayComponent({ mapType, overlay }: MapOverlayComponentProps) {
  const lmap = useMap();
  const { get } = useAxios();
  const { info } = useNotification();
  const { context } = useAppContext();
  const { map, mapOverlay, layers, setLayerItems, setMapOverlay } = useMaps();

  const [mapId, setMapId] = useState<number>();

  //const mapOverlay = map?.overlays?.find((ol) => ol.layers.find((l) => l.tagPrefix === 'AutoDWGLayers'));

  const [zoom, setZoom] = useState(MINZOOM);

  const getBounds = (_overlay: MapOverlay): LatLngBounds => {
    return new LatLngBounds([_overlay.south, _overlay.west], [_overlay.north, _overlay.east]);
  };

  const overlayRef = useRef<LeafletSVGOverlay>(null);
  // const MapOverlayComponent = (overlay: MapOverlay) => {
  const [overlayData, setOverlayData] = useState<string>();

  const resetMapView = () => {
    setZoom(MINZOOM);
    lmap.setView(MAPCENTER, MINZOOM, { animate: false });
  };

  useEffect(() => {
    //if (overlayRef.current) {
    setMapOverlay(overlay);
    //}
  }, []); // [overlayRef.current]);

  // useEffect(() => {
  //   if (overlayRef.current !== null && mapOverlay && mapType === 'edit' && layers.generate) {
  //     resetMapView();
  //     renderLayerItems(overlayRef, lmap, mapOverlay, context?.systemId!!).then((items) => {
  //       //const _items = items as unknown as MapItemVM[];
  //       //const result = items.filter((i) => ['vav', 'ahu', 'fsd'].find((w) => i.name.toLowerCase().indexOf(w) !== -1));
  //       info(`Generated ${items.length} items from layers`);
  //       setLayerItems(items);
  //     });
  //   } else setLayerItems([]);
  // }, [layers]);

  useEffect(() => {
    get<string>(`/api/map/layers/${context?.systemId}/${map?.id}/${overlay.name}`).then(setOverlayData);
  }, []);

  return (
    overlayData && (
      <SVGOverlay
        ref={overlayRef}
        className={mapType === 'edit' ? 'svg-border-blue' : ''}
        key={`mapoverlay_${overlay?.id}`}
        bounds={getBounds(mapOverlay ?? overlay)}
        // eventHandlers={overlayHandlers}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          id={`${`mapoverlaydata_${overlay?.id}`}`}
          dangerouslySetInnerHTML={{ __html: overlayData }}
        />
      </SVGOverlay>
    )
  );
}
