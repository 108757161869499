import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { SoloSelectFieldAttributes } from '.';

export interface SelectOption {
  label: string;
  value: number;
}

export const SoloSelectField: React.FC<SoloSelectFieldAttributes> = ({ label, name, data, disabled, control, required }) => {
  const { register, setValue } = useFormContext();

  // const [options, setOptions] = useState<SelectOption[]>([]);

  // useEffect(() => {
  //   setOptions(data.map((d) => ({ label: d.name, value: d.id })));
  // }, [data]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required }}
      disabled={disabled}
      render={({ field }) =>
        data && (
          <Autocomplete
            disablePortal
            disabled={disabled}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField autoComplete='off' {...params} label={label} />}
            options={data}
            {...field}
            {...register(name)}
            size='small'
            style={{ width: '100%', margin: '5px' }}
            value={field.value}
            onChange={(e, v) => {
              console.log(v);
              setValue(name, v.id);
            }}
          />
        )
      }
    />
  );
};
