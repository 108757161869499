import { Box, CircularProgress, IconButton, ListItem, ListItemText, Stack, Typography, useTheme } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender, useGridApiRef } from '@mui/x-data-grid';
import { DataGrid, RenderListItemProps } from 'components/dataGrid/DataGrid';
import { renderProgress } from 'components/dataGrid/renderers/ProgressBar';
import { Inspection } from 'entities';
import useAppContext from 'hooks/useAppContext';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Edit, PlayArrow, Pause } from '@mui/icons-material';
import { InspectionState } from 'entities/enums/InspectionState';
import useInspection from 'hooks/useInspection';
import { Entity } from 'entities/base/Entity';
import { ClientTitle } from 'components/shared/ClientTitle';
import { ThemeMode } from 'types/theme';
import BroadcastHelper from 'helpers/BroadcastHelper';
import { getDateString } from 'utils/dateHelper';

type InspectionsTableProps = {
  reload?: Symbol;
  columns?: GridColDef[];
  type: 'All' | 'Active' | 'Completed';
  limited?: boolean;
  disabled?: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
  noDataMessage?: string;
  pageSizeOptions?: number[];
};

interface RenderActionCellProps {
  inspection: Inspection;
}

const RenderActionCell = ({ inspection }: RenderActionCellProps): ReactNode => {
  const { handleState } = useInspection();

  const [loading, setLoading] = useState(false);

  useEffect(() => setLoading(false), [inspection.state]);

  return (
    <IconButton
      size='small'
      color='inherit'
      onClick={() => {
        setLoading(true);
        handleState(inspection.id).then(() => setLoading(false));
      }}
    >
      {loading ? <CircularProgress size={20} /> : inspection.state === InspectionState.Paused ? <PlayArrow /> : inspection.state === InspectionState.Active ? <Pause /> : <></>}
    </IconButton>
  );
};

const RenderListItemTemplate = ({ entity, disabled }: RenderListItemProps) => {
  const { context } = useAppContext();
  const [item, setItem] = useState(entity as Inspection);
  const { inspectionStateHandler } = BroadcastHelper();
  // const { inspectionId, setInspectionId } = useInspection();

  // const style = () => (inspectionId === entity.id ? { border: '1px, inset, #ffffff', backgroundColor: '#ffffff14' } : {});

  useEffect(() => {
    inspectionStateHandler(async (inspectonState: { id: number; state: InspectionState }) => {
      if (item.id === inspectonState.id) setItem({ ...item, state: inspectonState.state });
    });
  }, []);

  return (
    <ListItem>
      <Box>
        <Typography variant='h5'>{item.name}</Typography>
        <Box sx={{ mr: 2 }} display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} gap={2}>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
            {!disabled && <RenderActionCell inspection={item} />}
            <>{renderProgress(item.progress ?? 0)}</>
          </Box>
          <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'}>
            <Typography variant='subtitle2'>{`State: ${InspectionState[item.state]}`}</Typography>
            <Typography variant='subtitle2'>{`Due date: ${item.dueDate}`}</Typography>
          </Box>
        </Box>
        {!disabled && context?.hasSignals && item.activeUntil && item.state === InspectionState.Active && (
          <Typography variant='subtitle2'>{`Active until: ${getDateString(item.activeUntil, context?.timeZone)}`}</Typography>
        )}
      </Box>
    </ListItem>
  );
};

export default function InspectionsTable({ reload, type, noDataMessage, hideFooter, hideHeader, disabled, columns }: InspectionsTableProps) {
  const apiRef = useGridApiRef();
  const { context } = useAppContext();

  const [dataUrl, setDataUrl] = useState<string>();

  const _columns: GridColDef<Inspection>[] = [
    ...(disabled
      ? []
      : ([
          {
            field: 'edit',
            headerName: '',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell(params) {
              return <RenderActionCell inspection={params.row} />;
            },
          },
        ] as GridColDef<Inspection>[])),
    {
      field: 'progress',
      headerName: 'Progress',
      filterable: false,
      sortable: false,
      width: 110,
      disableColumnMenu: true,
      renderCell(params) {
        return renderProgress(params.row.progress ?? 0);
      },
    },
    {
      field: 'dueDate',
      headerName: 'Due date',
      filterable: false,
      sortable: false,
      width: 135,
      disableColumnMenu: true,
    },
    {
      field: 'state',
      headerName: 'State',
      filterable: false,
      width: 150,
      disableColumnMenu: true,
      renderCell(params) {
        return InspectionState[params.value];
      },
    },
    {
      field: 'name',
      headerName: 'Inspection',
      filterable: false,
      width: 400,
      disableColumnMenu: true,
    },
  ];

  const activeUntil: GridColDef<Inspection>[] = [
    {
      field: 'activeUntil',
      headerName: 'Active until',
      filterable: false,
      width: 200,
      disableColumnMenu: true,
      renderCell(params) {
        return params.row.state === InspectionState.Active && getDateString(params.value, context?.timeZone);
      },
    },
  ];

  // useEffect(() => {
  //   setRefresh(Symbol());
  // }, [inspectionId]);

  useEffect(() => {
    setDataUrl(`/api/inspection/query/${context?.systemId}/${type}`);
  }, [context?.systemId]);

  useEffect(() => {
    setDataUrl(`/api/inspection/query/${context?.systemId}/${type}`);
  }, []);

  return (
    <DataGrid
      api={apiRef}
      reload={reload}
      dataUrl={dataUrl}
      key={'inspectionsTable'}
      hideFooter={hideFooter}
      hideHeader={hideHeader}
      orderBy={type === 'Active' ? `dueDate asc` : 'date desc'}
      columnDefinitions={columns ?? [..._columns, ...(context?.hasSignals ? activeUntil : [])]}
      // listHeader={<ClientTitle prefix={} />}
      noDataMessage={noDataMessage ?? 'No active inspections pending'}
      listItemTemplate={(e) => <RenderListItemTemplate entity={e} disabled={disabled} />}
    />
  );
}
