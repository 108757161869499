import { Button } from '@mui/material';
import MainCard from 'components/cards/MainCard';
import { ClientTitle } from 'components/shared/ClientTitle';
import AddIcon from '@mui/icons-material/Add';
import { useLoaderData, useNavigate } from 'react-router-dom';
import PulseRelaysTable from 'collections/PulseRelaysTable';
import { Building, PulseRelay, SignalType } from 'entities';
import { useEffect, useState } from 'react';
import useAxios from 'hooks/useAxios';
import { PulseRelayForm } from 'forms/PulseRelayForm';
import Loader from 'components/shared/Loader';
import { SubmitHandler } from 'react-hook-form';
import logger from 'utils/logger';
import { toIsoDate } from 'utils/dateHelper';
import { GridColDef } from '@mui/x-data-grid';
import { EditTableCellAction } from 'components/actions/EditTableCellAction';
import { AppRoles } from 'entities/enums';
import useNotification from 'hooks/useNotification';
import _ from 'lodash';

export const loader = ({ params }: any) => params?.id?.toString() ?? '';

export const PulseRelaysView = () => {
  const navigate = useNavigate();
  const params = useLoaderData() as string;

  const { get, post, patch } = useAxios();
  const { error, success } = useNotification();

  const [id, setId] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [entity, setEntity] = useState<PulseRelay>();

  const actions: GridColDef<PulseRelay>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return EditTableCellAction(params, (id) => setId(id), [AppRoles.Admin]);
      },
    },
  ];

  const resetForm = () => {
    setId(undefined);
    setShowForm(false);
    setEntity(undefined);
  };

  const handleSubmit: SubmitHandler<PulseRelay> = (data) => {
    // cleanup entity
    // data.buildings.forEach((building) => {
    //   // var props = Object.keys(building as Building);
    //   // props.splice(props.indexOf('site'), 1);
    //   // console.log('BUILDING PROPS', props);
    //   // console.log('BUILDING', _.pick(building, props));
    //   // building = _.pick(building, props) as Building;

    // });

    // data.signalTypes.forEach((type) => {
    //   // var props = Object.keys(type as SignalType);
    //   // props.splice(props.indexOf('system'), 1);
    //   // console.log('TYPE PROPS', props);
    //   // console.log('TYPE', _.pick(type, props));
    //   // type = _.pick(type, props) as SignalType;

    //   type.system = undefined;
    //   type.pulseRelays = [];
    // });

    logger.log('PULSERELAY SUBMIT', data);
    if (data.id > 0) {
      patch<PulseRelay>(`/api/pulseRelay/${data.id}`, data).then((ok) => {
        if (ok) {
          resetForm();
          success(`Successfully updated group: ${data.name}`);
        } else error(`Failed to update group: ${data.name}`);
      });
    } else {
      post<PulseRelay>(`/api/pulseRelay`, data).then((ok) => {
        if (ok) {
          resetForm();
          success(`Successfully added new group: ${data.name}`);
        } else error(`Failed to add group: ${data.name}`);
      });
    }
  };

  useEffect(() => {
    if (id && id > 0) {
      get<PulseRelay>(`/api/pulseRelay/${id}`).then((e) => {
        setEntity(e);
        setShowForm(true);
      });
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    // methods.reset(InitialState);
    if (!isNaN(parseInt(params))) setId(parseInt(params));
  }, [params]);

  if (loading) return <Loader />;

  return (
    <MainCard
      title={showForm ? entity ? `Pulse Relay: ${entity.name}` : 'New Pulse Relay' : <ClientTitle prefix={'Pulse Relays for'} showClient />}
      secondary={
        !showForm && (
          <Button
            variant='outlined'
            startIcon={<AddIcon />}
            onClick={() => {
              setId(0);
              setShowForm(true);
            }}
          >
            Add Pulse Relay
          </Button>
        )
      }
    >
      {showForm ? <PulseRelayForm entity={entity} onSubmit={handleSubmit} onCancel={resetForm} /> : <PulseRelaysTable actions={actions} />}
    </MainCard>
  );
};
