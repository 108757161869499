import { MapLayers } from 'components/maps/MapLayers';
import MapMenu from './components/MapMenu';
import MinimapControl from './controls/MiniMap';
import { useWindowSize } from 'hooks/useWindowSize';
import { CRS, LatLngBounds } from 'leaflet';
import MapActionsControl from './controls/MapActions';
import { LeafletMapContainer } from './base/LeafletMapContainer';
import { useEffect, useRef, useState } from 'react';
import useMaps from 'hooks/useMaps';

export type MapType = 'alarm' | 'inspection' | 'edit' | 'view';

interface MapControlProps {
  height?: string | number;
  mapType: MapType;
}

export const MINZOOM = 2;
export const MAXZOOM = 7;
export const MAPCENTER = {
  lat: -128,
  lng: 128,
};

export const MapContainer = ({ height, mapType }: MapControlProps) => {
  const { map } = useMaps();
  const { width } = useWindowSize();
  const [_className, setClassName] = useState<'map-container ' | 'map-container-ligth '>('map-container ');

  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const className = map && map?.id <= 10 ? 'map-container-ligth ' : 'map-container ';
    const element = mapRef.current?.firstChild as HTMLDivElement;
    element.className = element.className.replace(_className, className);
    setClassName(className);
  }, [map]);

  return (
    <div ref={mapRef}>
      <LeafletMapContainer
        crs={CRS.Simple}
        trackResize={true}
        zoom={MINZOOM}
        center={MAPCENTER}
        fullscreenControl={true}
        className={'map-container'}
        attributionControl={false}
        scrollWheelZoom={(width ?? 900) >= 900}
        style={{ height: height ?? '60vh' }}
        maxBounds={new LatLngBounds([0, 0], [-256, 256])}
      >
        <MapLayers mapType={mapType} />
        {mapType === 'edit' && <MapMenu />}
        <MapActionsControl mapType={mapType} />
        <MinimapControl width={(width ?? 450) >= 450 ? 210 : 80} height={(width ?? 450) >= 450 ? 210 : 80} zoom={MINZOOM - 2} />
      </LeafletMapContainer>
    </div>
  );
};
