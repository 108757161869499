import { useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Incident } from 'entities';
import { FieldAttributes, FieldType } from 'components/fields';
import { BaseForm } from './BaseForm';
import { EventState } from 'entities/enums';
import Wizard from 'components/shared/Wizard';

const InitialState: Incident = {
  name: '',
  id: 0,
  state: EventState.Open,
  notified: false,
  buildingID: 0,
  createdByID: '',
};

type IncidentFormProps = {
  entity?: Incident;
  disabled?: boolean;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<Incident>;
};

export const IncidentForm = ({ disabled, entity, onCancel, onSubmit }: IncidentFormProps) => {
  const methods = useForm<Incident>({
    defaultValues: useMemo(() => {
      return entity ?? InitialState;
    }, [entity]),
  });

  const formSchema: FieldAttributes[] = [
    {
      label: 'Name',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
    },
  ];

  return <Wizard />;

  return <BaseForm methods={methods} readOnly={disabled} formSchema={formSchema} onCancel={onCancel} onSubmit={(d: Incident) => onSubmit(d)} />;
};
