import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import MainCard from 'components/cards/MainCard';
import Transitions from 'components/extended/Transitions';
import useAppContext from 'hooks/useAppContext';
import useAxios from 'hooks/useAxios';
import { ClientTitle } from 'components/shared/ClientTitle';
import { AppRoles, SystemState } from 'entities/enums';
import { inRole } from 'utils/extensions';
import { IconButton } from '@mui/material';
import { Save } from '@mui/icons-material';

// ==============================|| NOTIFICATION ||============================== //

const StateSection = () => {
  const theme = useTheme();
  const { patch } = useAxios();
  const { context, contact } = useAppContext();

  const [dirty, setDirty] = useState(false);

  const downMD = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);

  const [state, setState] = useState(context?.systemState ?? 0);
  const [value, setValue] = useState(context?.systemState ?? 0);

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef<any>(null);

  const handleToggle = () => {
    if (inRole(contact?.role, [AppRoles.Admin, AppRoles.Inspector, AppRoles.Responder])) setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    if (event?.target.value) {
      const _state = Number(event?.target.value);
      setValue(_state);
      setDirty(_state !== state);
    }
  };

  const handleSubmit = () => {
    // if (value && building?.buildingId) {
    patch(`/api/system/${context?.systemId}/state/${value}`).then(() => {
      setOpen(false);
    });
    // }
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    setState(context?.systemState ?? SystemState.Normal);
    setValue(context?.systemState ?? SystemState.Normal);
  }, [context]);

  if (!context?.hasSignals) return <></>;

  return (
    <>
      <Box sx={{ ml: 2 }}>
        <Button
          variant='contained'
          sx={{
            height: 45,
            width: 110,
            backgroundColor: state === SystemState.Normal ? 'green' : state === SystemState.Offline ? 'red' : '#f0ad4e',
            color: 'white',
          }}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={handleToggle}
        >
          <b>{SystemState[state].toUpperCase()}</b>
        </Button>
      </Box>

      <Popper
        placement={downMD ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [downMD ? 5 : 0, 20],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions position={downMD ? 'top' : 'top-right'} in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                    <Grid container direction='column' spacing={2}>
                      <Grid size={{ xs: 12 }}>
                        <Grid container alignItems='center' justifyContent='space-between' sx={{ pt: 2, px: 2 }}>
                          <Grid>
                            <Stack direction='row' spacing={2}>
                              <Typography variant='subtitle1'>
                                <ClientTitle prefix={'Set state for'} showBuildingShortName showSystem />
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid size={{ xs: 12 }}>
                        <Grid container direction='column' spacing={2}>
                          <Grid size={{ xs: 12 }}>
                            <Box display={'flex'} justifyContent={'center'} gap={1} alignItems={'center'} sx={{ px: 2, pt: 0.25, minWidth: 200 }}>
                              <TextField
                                autoComplete='off'
                                select
                                size='small'
                                fullWidth
                                value={value}
                                label={'State'}
                                onChange={handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                {Object.keys(SystemState)
                                  .filter((k) => isNaN(parseInt(k)))
                                  .map((o, k) => (
                                    <option key={o} value={k}>
                                      {o.toUpperCase()}
                                    </option>
                                  ))}

                                {/* {status.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))} */}
                              </TextField>
                              <IconButton disabled={!dirty} onClick={handleSubmit}>
                                <Save />
                              </IconButton>
                            </Box>
                          </Grid>

                          {/*<Grid size={{ xs: 12 }}>
                            <Grid container alignItems='center' justifyContent='space-between'>
                              <Grid>
                                <Typography variant='subtitle1'>Set notifications {notify ? 'OFF' : 'ON'}</Typography>
                              </Grid>
                              <Grid>
                                <Switch color='primary' checked={notify} onChange={(e) => setNotify(e.target.checked)} name='sdm' size='small' />
                              </Grid>
                            </Grid>
                          </Grid> */}

                          <Grid size={{ xs: 12 }} p={0}>
                            <Divider sx={{ my: 0 }} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                      <Button size='small' disableElevation onClick={handleSubmit}>
                        Save
                      </Button>
                    </CardActions> */}
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default StateSection;
