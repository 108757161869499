import { Button } from '@mui/material';
import MainCard from 'components/cards/MainCard';
import { ClientTitle } from 'components/shared/ClientTitle';
import AddIcon from '@mui/icons-material/Add';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Issue } from 'entities';
import { useEffect, useState } from 'react';
import useAxios from 'hooks/useAxios';
import Loader from 'components/shared/Loader';
import { SubmitHandler } from 'react-hook-form';
import logger from 'utils/logger';
import { ImportExportOutlined } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid';
import { EditTableCellAction } from 'components/actions/EditTableCellAction';
import { AppRoles, IssueType } from 'entities/enums';
import IssuesTable from 'collections/IssuesTable';
import { IssueForm } from 'forms/IssueForm';
import useNotification from 'hooks/useNotification';
import useAppContext from 'hooks/useAppContext';
import useConfirm from 'hooks/useConfirm';

export const loader = ({ params }: any) => params?.id?.toString() ?? '';

export const IssuesView = () => {
  const params = useLoaderData() as string;

  const navigate = useNavigate();
  const { get, post, patch } = useAxios();
  const { context } = useAppContext();
  const { error, success } = useNotification();
  const { confirm, ConfirmDialog } = useConfirm();

  const [id, setId] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [entity, setEntity] = useState<Issue>();

  const resetForm = () => {
    setId(undefined);
    setShowForm(false);
    setEntity(undefined);
  };

  const actions: GridColDef<Issue>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return EditTableCellAction(params, (id) => setId(id), [AppRoles.Admin]);
      },
    },
  ];

  const handleSubmit: SubmitHandler<Issue> = (data) => {
    if (context && data) {
      logger.log('Issue SUBMIT', data);
      if (data.id > 0) {
        data.device = undefined;
        patch<Issue>(`/api/issue`, data).then((ok) => {
          if (ok) {
            setShowForm(false);
            success(`Successfully updated issue: ${data.name}`);
          } else error(`Failed to update issue: ${data.name}`);
        });
      } else {
        data.date = new Date(new Date().toUTCString()).toISOString();
        data.systemId = context.systemId;
        data.expectedCompletion = undefined;
        // data.notes = [];
        post<Issue>(`/api/issue`, data).then((ok) => {
          if (ok) {
            setShowForm(false);
            success(`Successfully added new issue: ${data.name}`);
          } else error(`Failed to add issues: ${data.name}`);
        });
      }
      resetForm();
    }
  };

  const handleCloseIssue = () => {
    confirm(`Are you sure you want to close this issue?`).then((ok) => {
      if (ok && entity) entity.closed = true;
    });
  };

  useEffect(() => {
    if (id && id > 0) {
      get<Issue>(`/api/issue/${id}`).then((e) => {
        setEntity(e);
        setShowForm(true);
      });
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    // methods.reset(InitialState);
    if (!isNaN(parseInt(params))) setId(parseInt(params));
  }, [params]);

  if (loading) return <Loader />;

  return (
    <MainCard
      title={
        showForm ? entity ? `${entity.name} [${entity.closed ? 'Closed' : entity.state?.name}]` : 'New Issue' : <ClientTitle prefix={'Issue Tracker for'} showBuilding showSystem />
      }
      secondary={
        showForm ? (
          entity ? (
            <Button variant='outlined' onClick={handleCloseIssue}>
              Close issue
            </Button>
          ) : (
            <></>
          )
        ) : (
          <Button
            variant='outlined'
            startIcon={<AddIcon />}
            onClick={() => {
              setShowForm(true);
            }}
          >
            Add Issue
          </Button>
        )
      }
    >
      {showForm ? <IssueForm entity={entity} onSubmit={handleSubmit} onCancel={resetForm} /> : <IssuesTable actions={actions} />}
      <ConfirmDialog />
    </MainCard>
  );
};
