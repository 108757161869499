import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataGrid } from 'components/dataGrid/DataGrid';
import TabPanel from 'components/shared/TabPanel';
import { App } from 'entities';
import { Pagination } from 'entities/base/Pagination';
import useAxios, { QueryOptions } from 'hooks/useAxios';
import { useEffect, useState } from 'react';

interface IAppModel {
  id: number;
}

interface WorkOrder extends IAppModel {
  number: string;
  finishDate: string;
  workOrderTypeDescription: string;
  actionRequested: string;
}

const InitialOptions: QueryOptions = {
  page: 0,
  pageSize: 10,
  orderBy: 'id desc',
  search: '',
};

export const AppComponent = () => {
  const FacilitiesTab = () => {
    const columns: GridColDef<WorkOrder>[] = [
      {
        field: 'name',
        headerName: 'Building',
        filterable: false,
        width: 200,
      },
    ];
    return (
      <Box>
        <Typography variant='h4'>American Airline Facilities</Typography>
        <DataGrid key={'facilitiesTable'} hideHeader={false} orderBy={'name asc'} columnDefinitions={columns} dataUrl={`/api/app/tma/workorders`} />
      </Box>
    );
  };

  const WorkOrdersTab = () => {
    const columns: GridColDef<WorkOrder>[] = [
      {
        field: 'number',
        headerName: 'Work Order #',
        filterable: false,
        width: 200,
      },
      {
        field: 'finishDate',
        headerName: 'Due Date',
        filterable: false,
        width: 200,
        renderCell(params) {
          return params.value ? new Date(params.value).toDateString() : '';
        },
      },
      {
        field: 'workOrderTypeDescription',
        headerName: 'Description',
        filterable: false,
        width: 300,
      },
      {
        field: 'actionRequested',
        headerName: 'Request',
        filterable: false,
        width: 550,
      },
    ];

    return (
      <Box>
        <Typography variant='h4'>My Open Work List</Typography>
        <DataGrid key={'workOrdersTable'} hideHeader={false} orderBy={'name asc'} columnDefinitions={columns} dataUrl={`/api/app/tma/workorders`} />
      </Box>
    );
  };

  const InfoTab = () => (
    <Box>
      <Typography>Personal Info</Typography>
    </Box>
  );

  return (
    <TabPanel
      selected={1}
      items={[
        { index: 0, label: 'Facilities', children: <FacilitiesTab /> },
        { index: 1, label: 'Work Orders', children: <WorkOrdersTab /> },
        { index: 2, label: 'Personal Info', children: <InfoTab /> },
      ]}
    />
  );
};
