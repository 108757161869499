import { useEffect, useState } from 'react';
import { gridSpacing } from 'config';
import { MapContainer } from 'components/maps/MapContainer';
import MainCard from 'components/cards/MainCard';
import { ClientTitle } from 'components/shared/ClientTitle';
import DevicesList from 'collections/DevicesList';
import { DragDropContext } from '@hello-pangea/dnd';
import { StrictModeDroppable } from 'components/dragDrop/StrictModeDroppable';
import { Map, MapItem } from 'entities';
import useAxios from 'hooks/useAxios';
import useNotification from 'hooks/useNotification';
import { GridColDef } from '@mui/x-data-grid';
import { Link, Grid2 as Grid, Box, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid } from '../components/dataGrid/DataGrid';
import { useNavigate } from 'react-router';
import TableChartIcon from '@mui/icons-material/TableChart';
import MapIcon from '@mui/icons-material/Map';
import { MapSearch } from 'components/maps/components/MapSearch';
import { Toggler } from 'components/shared/Toggler';
import { MapSelect } from 'components/maps/components/MapSelect';
import { MapSelectLayer } from 'components/maps/components/MapSelectLayer';
import useMaps from 'hooks/useMaps';
import { EditTableCellAction } from 'components/actions/EditTableCellAction';
import { MapGenerator } from 'components/maps/components/MapGenerator';
import useAppContext from 'hooks/useAppContext';
import { AppRoles } from 'entities/enums';
import { inRole } from 'utils/extensions';
import { MapBounds } from 'components/maps/components/MapBounds';

export const loader = ({ params }: any) => params?.id?.toString() ?? '';

export const MapsEditView = () => {
  const { contact, context } = useAppContext();
  const { post } = useAxios();
  const navigate = useNavigate();
  const { success } = useNotification();
  const { map, markerItem, addMarker, setLayers, refreshMap } = useMaps();

  const [reload, setReload] = useState<Symbol>();
  const [dragId, setDragId] = useState<number>();

  const [type, setType] = useState<'Unmapped' | 'Uninspected'>('Unmapped');

  const [component, setComponent] = useState('map');

  const [id, setId] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [entity, setEntity] = useState<Map>();

  const resetForm = () => {
    setId(undefined);
    setShowForm(false);
    setEntity(undefined);
  };

  const actions: GridColDef<Map>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return EditTableCellAction(params, (id) => setId(id), [AppRoles.Admin]);
      },
    },
  ];

  const columns: GridColDef<Map>[] = [
    //{ field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Name',
      width: 400,
      filterable: false,
      renderCell(params) {
        return (
          <Link component={RouterLink} to={`/mapsEdit/${params.row.id}`}>
            {params.value?.toString()}
          </Link>
        );
      },
    },
  ];

  const handleDragEnd = () => {
    if (markerItem) {
      post<MapItem>('/api/mapitem', { ...markerItem, deviceId: dragId }).then(() => {
        success(`Sucessfully added map item`);
        addMarker(undefined);
        refreshMap();
        setReload(Symbol());
      });
    }
  };
  // const showInfo = (id: string) => {};

  useEffect(() => {
    setLayers({});

    // return () => {
    //   // reset default layers
    //   setSelectedLayers({ ...selectedLayers, floorplan: true, devices: true });
    // };
  }, []);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid size={{ xs: 12 }}>
        <MainCard
          title={<ClientTitle prefix={'Maps for'} showBuildingShortName showSystem />}
          secondary={
            <Toggler
              id='maps-settings-view'
              value={component}
              values={[
                { name: 'table', icon: <TableChartIcon /> },
                { name: 'map', icon: <MapIcon /> },
              ]}
              onChange={setComponent}
            />
          }
        >
          {component === 'table' && <DataGrid id={'mapsEdit'} dataUrl={`/api/map/query/${context?.systemId}`} columnDefinitions={[...actions, ...columns]} hideHeader={false} />}
          {component === 'map' && (
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, lg: 3 }}>{inRole(contact?.role, [AppRoles.Admin]) && <MapGenerator />}</Grid>
              <Grid size={{ xs: 12, lg: 3 }}>
                <MapSelectLayer />
              </Grid>
              <Grid size={{ xs: 12, lg: 3 }}>
                <MapSearch />
              </Grid>
              <Grid size={{ xs: 12, lg: 3 }}>
                <MapSelect />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <DragDropContext onDragEnd={handleDragEnd} onDragStart={(e) => setDragId(parseInt(e.draggableId))}>
                  <StrictModeDroppable droppableId='droppable'>
                    {(provided, snapshot) => (
                      <Box component='div' sx={{ width: '100%', height: '100%' }} ref={provided.innerRef} {...provided.droppableProps}>
                        <Grid container spacing={gridSpacing}>
                          <Grid size={{ xs: 12, lg: 9 }}>
                            <Box component='div' ref={provided.innerRef} {...provided.droppableProps} sx={{ cursor: 'crosshair' }}>
                              <MapContainer mapType='edit' height={'70vh'} />
                            </Box>
                          </Grid>
                          <Grid size={{ xs: 12, lg: 3 }}>
                            {inRole(contact?.role, [AppRoles.Admin]) && (
                              <>
                                <Divider sx={{ marginBottom: 2 }} />
                                <Box display={'flex'} justifyContent={'center'} sx={{ width: '100%' }}>
                                  <MapBounds />
                                </Box>
                                <Divider sx={{ marginTop: 2 }} />
                              </>
                            )}
                            <DevicesList type={type} reload={reload} />
                            {/* <TabPanel
                              selected={1}
                              items={[
                                { index: 0, label: 'Bounds', children: },
                                { index: 1, label: 'Devices', children: <DevicesList type={type} /> },
                              ]}
                            /> */}
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </StrictModeDroppable>
                </DragDropContext>
              </Grid>
            </Grid>
          )}
        </MainCard>
      </Grid>
    </Grid>
  );
};
