import { ReactNode, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, Divider, styled, Typography } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

interface CollapsableContainerProps extends IconButtonProps {
  children: ReactNode;
  title?: string;
  header?: ReactNode;
  expanded: boolean;
  onClick: () => void;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

export const CollapsableContainer = ({ children, title, header, expanded, onClick }: CollapsableContainerProps) => {
  // const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        {title && <Typography variant='h5'>{title}</Typography>}
        {header}
        <ExpandMore expand={expanded} onClick={onClick} aria-expanded={expanded} aria-label='show inspection'>
          <ExpandMoreIcon />
        </ExpandMore>
      </Box>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <Divider sx={{ mb: 2 }} />
        {children}
      </Collapse>
    </>
  );
};
