import { CircularProgress, IconButton, Stack } from '@mui/material';
import { InventoryOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { InspectionResultState } from 'entities/enums';

type InspectionDeviceActionProps = {
  id?: number;
  state: InspectionResultState;
  onClick: (deviceId?: number) => void;
};

export const InspectionDeviceAction = ({ id, state, onClick }: InspectionDeviceActionProps) => {
  const [disabled, setDisabled] = useState(false);

  const getFillColor = () =>
    state === InspectionResultState.Pending ? '#d9534' : state === InspectionResultState.Partially ? '#f0ad4e' : state === InspectionResultState.Failed ? '#ff0000' : '#6cc067';

  const handlePassed = () => {
    setDisabled(true);
  };

  const handleFail = () => {
    setDisabled(true);
  };

  if (id) {
    return (
      <Stack direction='row' spacing={1} alignItems='center' height={'100%'}>
        <IconButton onClick={(_) => onClick(id)}>
          <InventoryOutlined sx={{ width: 20, height: 20, fill: getFillColor() }} />
        </IconButton>
      </Stack>
    );
  }

  return disabled ? (
    <Stack direction='row' spacing={1} alignItems='center' height={'100%'}>
      <CircularProgress size={20} />
    </Stack>
  ) : (
    <Stack direction='row' spacing={1} alignItems='center' height={'100%'}>
      <InventoryOutlined sx={{ width: 20, height: 20, fill: true ? '#6cc067' : '#d9534' }} />
    </Stack>
  );
};
