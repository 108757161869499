// import { EventInput } from '@fullcalendar/core';

import moment from 'moment';

type EventInput = {};

let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

enum Months {
  January = 1,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export const INITIAL_EVENTS: EventInput[] = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: todayStr,
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: todayStr + 'T12:00:00',
  },
];

export function createEventId() {
  return String(eventGuid++);
}

export const monthYear = (date: string) => {
  const segments = date.split('-');
  const month = parseInt(segments[1]);
  return segments.length > 2 && !isNaN(month) ? `${Months[month]} ${segments[0]}` : date;
};

export const toMonthYearNumeric = (isoDate: string) => {
  const segments = isoDate.split('-');
  const month = parseInt(segments[1]);
  return segments.length > 2 && !isNaN(month) ? `${month}-${segments[0]}` : isoDate;
};

export const toIsoDate = (monthYear: string) => {
  const segments = monthYear.split('-');
  return segments.length === 2 ? `${segments[1]}-${segments[0]}-01T00:00:00.0000000` : monthYear;
};

export const getDateString = (value?: string | undefined, timeZone?: string, showLocale: boolean = false, type: 'Date' | 'Time' | 'DateTime' = 'DateTime') => {
  if (!value) return '';

  // console.log(context?.timeZone, startTime.toLocaleString(undefined, { timeZone: context?.timeZone }));

  if (value.lastIndexOf('Z') === -1) value += 'Z';

  // const defaultOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  const options: Intl.DateTimeFormatOptions = showLocale ? {} : { timeZone: timeZone };

  return type === 'Date'
    ? new Date(value).toLocaleDateString(undefined, options)
    : type === 'Time'
      ? new Date(value).toLocaleTimeString(undefined, options)
      : new Date(value).toLocaleString(undefined, options);
};

export const getLocalZone = (timeZone?: string): Intl.LocalesArgument => {
  // TODO implement
  return 'en-US';
};

export const getMomentum = (
  date: Date,
  timeZone?: string,
  options?: Intl.DateTimeFormatOptions,
  amount: moment.DurationInputArg1 = 2,
  unit: moment.unitOfTime.DurationConstructor = 'd'
) => {
  const isRecent = date.getTime() >= moment(new Date()).local().subtract(amount, unit).toDate().getTime();
  return isRecent ? moment(new Date(date)).local().startOf('seconds').fromNow() : new Date(date).toLocaleString(getLocalZone(timeZone), options);
};
