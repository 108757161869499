import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Photo } from 'entities';
import useAxios from 'hooks/useAxios';
import Skeleton from '@mui/material/Skeleton';

type ImageCarouselProps = {
  photos?: Photo[];
};

export default function ImageCarousel({ photos }: ImageCarouselProps) {
  const theme = useTheme();
  const { get } = useAxios();
  const maxSteps = photos?.length ?? 0;
  const [activeStep, setActiveStep] = React.useState(0);

  const [content, setContent] = React.useState<string>();

  const [items, setItems] = React.useState<Photo[]>();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  React.useEffect(() => {
    if (photos && photos.length > 0) {
      setItems(photos);
      get<string>(`/api/photo/${photos[activeStep].id}`).then(setContent);
    } else {
      setItems(undefined);
      setContent(undefined);
    }
  }, [photos]);

  React.useEffect(() => {
    if (items) {
      setContent(undefined);
      get<string>(`/api/photo/${items[activeStep].id}`).then(setContent);
    }
  }, [activeStep]);

  return (
    items &&
    items.length > 0 && (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ height: '100%', width: '100%', p: 2 }}>
          {content ? (
            <img style={{ width: '100%', height: '100%' }} src={content} alt={items[activeStep].description} loading='lazy' />
          ) : (
            <Skeleton variant='rectangular' width={'250px'} height={'300px'} />
          )}
        </Box>
        {/* <Box sx={{ height: 255, maxWidth: 400, width: '100%', p: 2 }}>{itemData[activeStep].description}</Box> */}
        <MobileStepper
          variant='text'
          steps={maxSteps}
          position='static'
          activeStep={activeStep}
          nextButton={
            <Button size='small' onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              Next
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Back
            </Button>
          }
        />
      </Box>
    )
  );
}
