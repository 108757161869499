import { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Skeleton from '@mui/material/Skeleton';
import { Photo } from 'entities';
import useAxios from 'hooks/useAxios';
import useAppContext from 'hooks/useAppContext';
import { useWindowSize } from 'hooks/useWindowSize';

type RenderPhotoItemProps = {
  item: Photo;
  isMobile?: boolean;
};

const RenderPhotoItem = ({ item, isMobile }: RenderPhotoItemProps) => {
  const { get } = useAxios();

  const [content, setContent] = useState<string>();

  useEffect(() => {
    get<string>(`/api/photo/${item.id}`).then(setContent);
  }, [item]);

  if (!content) return <Skeleton variant='rectangular' width={isMobile ? '90vw' : '20vw'} height={'40vh'} />;

  return (
    <ImageListItem key={item.id}>
      <img src={content} alt={item.name} loading='lazy' />
      <ImageListItemBar title={item.description?.split('\r\n')[0]} subtitle={item.description?.split('\r\n')[2]} />
    </ImageListItem>
  );
};

export default function PhotosGallery() {
  const { get } = useAxios();
  const { width } = useWindowSize();
  const { context } = useAppContext();
  const [photos, setPhotos] = useState<Photo[]>();

  const [mobile, setMobile] = useState<boolean>();

  useEffect(() => {
    setMobile((width ?? 1000) < 1000);
  }, [width]);

  useEffect(() => {
    get<Photo[]>(`/api/photo/all/${context?.systemId}`).then(setPhotos);
  }, []);

  return (
    photos && (
      <ImageList cols={mobile ? 1 : 3}>
        {photos.map((p) => (
          <RenderPhotoItem key={p.id} item={p} isMobile={mobile} />
        ))}
      </ImageList>
    )
  );
}
