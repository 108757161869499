import { IconButton, Stack } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { ReactNode } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import useAppContext from 'hooks/useAppContext';
import { inRole } from 'utils/extensions';
import { AppRoles } from 'entities/enums/AppRoles';
import { InfoRounded } from '@mui/icons-material';

export const EditTableCellAction = (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>, callback: (id: number) => void, roles: AppRoles[]): ReactNode => {
  const { contact } = useAppContext();

  // TODO show view icon if readable
  if (!inRole(contact?.role, roles))
    return (
      <Stack direction='row' spacing={1} alignItems='center' height={'100%'}>
        <IconButton size='small' aria-label='edit' color='inherit' onClick={() => callback(params.row.id)}>
          <InfoRounded />
        </IconButton>
      </Stack>
    );

  //logger.log(params);
  return (
    <Stack direction='row' spacing={1} alignItems='center' height={'100%'}>
      <IconButton size='small' aria-label='edit' color='inherit' onClick={() => callback(params.row.id)}>
        <EditIcon />
      </IconButton>
    </Stack>
  );
};
