import { GridColDef } from '@mui/x-data-grid';
import { DataGrid, DataGridActionProps } from 'components/dataGrid/DataGrid';
import { BuildingVM } from 'entities/viewModels';
import useAppContext from 'hooks/useAppContext';

interface BuildingsTableActions extends DataGridActionProps<BuildingVM> {}

export default function BuildingsTable({ actions }: BuildingsTableActions) {
  const { context } = useAppContext();
  const columns: GridColDef<BuildingVM>[] = [
    {
      field: 'name',
      headerName: 'Name',
      filterable: false,
      width: 200,
    },
    {
      field: 'shortName',
      headerName: 'ShortName',
      filterable: false,
      width: 150,
    },
    {
      field: 'site.name',
      headerName: 'Site',
      filterable: false,
      width: 300,
      renderCell(params) {
        return params.row.site?.name ?? '';
      },
    },
    {
      field: 'site.timezone',
      headerName: 'Timezone',
      filterable: false,
      width: 150,
      renderCell(params) {
        return params.row.site?.timeZoneId ?? '';
      },
    },
  ];

  return (
    <DataGrid
      key={'buildingsTable'}
      dataUrl={`/api/building/query/${context?.clientId}`}
      hideHeader={false}
      orderBy={'name asc'}
      columnDefinitions={actions ? [...actions, ...columns] : columns}
    />
  );
}
